import { Link } from '@material-ui/core'
import { LinkProps } from '@material-ui/core/Link'
import { Launch } from '@material-ui/icons'
import React from 'react'

type TProps = {
  children: string | string[] | ReactNode
  href: string
  color?: LinkProps['color']
  noIcon?: boolean
}

export const ExternalLink: React.FC<TProps> = ({
  href,
  color = 'textSecondary',
  noIcon = false,
  children,
}) => {
  return (
    <Link
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      color={color}
      component="a"
    >
      {children}{' '}
      {!noIcon && (
        <Launch fontSize="small" style={{ verticalAlign: 'text-bottom' }} />
      )}
    </Link>
  )
}
