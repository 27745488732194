import React from 'react'

import _ from 'lodash'

import { Grid, makeStyles, TextField } from '@material-ui/core'

import { CopyToClipboard } from '../../../../../molecules/CopyToClipboard'

export const useStyles = makeStyles(theme => ({
  copy: {
    fontSize: '2rem',
    position: 'relative',
    top: '1.5rem',
    marginRight: '1rem',
  },
}))

export function ShopifyCredentialBox({
  label,
  value,
}: NoChildren & {
  label: string
  value: string
}) {
  const classes = useStyles()
  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <CopyToClipboard text={value} className={classes.copy} />
      </Grid>
      <Grid item lg={11}>
        <TextField
          label={label}
          value={value}
          onChange={_.noop}
          onFocus={ev => {
            ev.target.select()
          }}
          fullWidth
          margin="normal"
        />
      </Grid>
    </Grid>
  )
}
