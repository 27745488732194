import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { InputAdornment } from '@material-ui/core'
import React from 'react'

import { FormInputField } from './forms/FormInputField'

type TProps = NoChildren

const regex = /^[0-9a-z-]+$/

export const ShopNameFormInput: React.FC<TProps> = props => {
  const validate = React.useCallback((value: string) => {
    if (!regex.test(value)) {
      return i18n._(
        t`Zadejte název obchodu tak jak je vedený v Shopify (text před myshopify.com)`,
      )
    }
    return
  }, [])
  return (
    <FormInputField
      fieldName="shopName"
      validate={validate}
      label={i18n._(t`Název obchodu`)}
      required
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">.myshopify.com</InputAdornment>
        ),
      }}
    />
  )
}
