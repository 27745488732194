import React from 'react'

import { ComGate, Confirmo, GoPay, TAccount } from 'shopify-payments-types'

import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { usePageTitle } from '../../../hooks/usePageTitle'
import { VerticalGap } from '../../../molecules/VerticalGap'
import { ComGateActivationView } from './comgate/ComGateActivationView'
import { ConfirmoActivationView } from './confirmo/ConfirmoActivationView'
import { AccountDashboardDeleteAccount } from './dashboard/AccountDashboardDeleteAccount'
import { GoPayActivationView } from './gopay/GoPayActivationView'

type TProps = NoChildren & {
  account: TAccount
}

export const AccountViewActivation: React.FC<TProps> = ({ account }) => {
  usePageTitle(i18n._(t`Průvodce aktivací propojení`), account.id)
  const view = (() => {
    switch (account.segmentKey) {
      case 'gopay':
        return (
          <GoPayActivationView account={account as GoPay.TSegmentAccount} />
        )
      case 'comgate':
        return (
          <ComGateActivationView account={account as ComGate.TSegmentAccount} />
        )
      case 'confirmo':
        return (
          <ConfirmoActivationView
            account={account as Confirmo.TSegmentAccount}
          />
        )
      default:
        return null
    }
  })()

  if (view) {
    return (
      <>
        {view}
        <VerticalGap />
        <AccountDashboardDeleteAccount account={account} />
      </>
    )
  }

  return null
}
