import React from 'react'

import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'

import { FormDropdownField } from '../../../../molecules/forms/FormDropdownField'

type TProps = NoChildren

type TOption = {
  value: string
  label: string
}
const currencies: RoA<TOption> = [
  'USD',
  'EUR',
  'CZK',
  'GBP',
  'PLN',
  'BTC',
  'LTC',
].map(curr => ({ value: curr, label: curr }))

export const ConfirmoConfigSettlementCurrency: React.FC<TProps> = props => {
  const localizedCurrencies = currencies.concat({
    value: 'SAME',
    label: i18n._(t`STEJNE KRYPTO KTERE ZAKAZNIK POSILA`),
  })
  return (
    <>
      <Typography>
        <Trans>Settlement měna</Trans>
      </Typography>
      <FormDropdownField<TOption>
        required
        fieldName="settlementCurrency"
        options={localizedCurrencies}
        label={i18n._(t`V jaké měně přijme Confirmo platbu`)}
        validate={value => {
          if (!value) {
            return i18n._(t`Měna musí být vybrána`)
          }
          return
        }}
      />
    </>
  )
}
