import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { collections } from 'shopify-payment-core'
import { TRegistration } from 'shopify-payments-types'

import { ROUTE_ACCOUNT, ROUTE_ROOT } from '../app/routes'
import { usePageTitle } from '../hooks/usePageTitle'
import { ProgressMessage } from '../molecules/ProgressMessage'
import { getFirebase } from '../tools/firebase'
import { Sentry } from '@speedlo/sentry'

type TProps = NoChildren

export const RegisterFinishView: React.FC<TProps> = () => {
  usePageTitle(i18n._(t`Dokončení registrace`))

  const { firestore } = getFirebase()
  const history = useHistory()

  const match = useRouteMatch<{ registrationId: string }>()!

  React.useEffect(() => {
    if (!match) {
      history.replace(ROUTE_ROOT)
      return
    }
    const { registrationId } = match.params
    return firestore
      .collection(collections.register)
      .doc(registrationId)
      .onSnapshot(
        snapshot => {
          if (!snapshot.exists) {
            return
          }
          const reg = snapshot.data() as TRegistration
          if (reg.status === 'success') {
            // accountId and registrationId are essentially the same thing 😊
            history.replace(`${ROUTE_ACCOUNT}/${registrationId}`)
          }
        },
        error => {
          Sentry.captureException(error)
        },
      )
  }, [firestore, history, match])

  return (
    <ProgressMessage>
      <Trans>Za malý moment budeme pokračovat. Prosíme o strpení...</Trans>
    </ProgressMessage>
  )
}
