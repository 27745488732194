import { AppBar, makeStyles, Toolbar } from '@material-ui/core'
import React from 'react'

import { PageTitle } from '../../molecules/PageTitle'
import { TopBarLogo } from '../../molecules/TopBarLogo'

type TProps = NoChildren

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
}))

export const GuestTopBar: React.FC<TProps> = () => {
  const classes = useStyles()

  return (
    <AppBar className={classes.root} color="primary" position="fixed">
      <Toolbar>
        <TopBarLogo />
        <PageTitle />
      </Toolbar>
    </AppBar>
  )
}
