import React from 'react'

import { AccountDashboardLanguagePicker } from '../dashboard/components/AccountDashboardLanguagePicker'
import { AccountDashboardPaymentLabel } from '../dashboard/components/AccountDashboardPaymentLabel'
import { ComGateConfigBankAccount } from './ComGateConfigBankAccount'

type TProps = NoChildren

export const ComGateConfig: React.FC<TProps> = props => {
  return (
    <>
      <AccountDashboardLanguagePicker languages={getLanguages()} />
      <AccountDashboardPaymentLabel />
      <ComGateConfigBankAccount />
    </>
  )
}

function getLanguages() {
  return [
    { value: 'cs', label: 'Čeština' },
    { value: 'en', label: 'Angličtina' },
    { value: 'sk', label: 'Slovenština' },
    { value: 'pl', label: 'Polština' },
  ]
}
