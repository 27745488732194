import React from 'react'

import { useRouteMatch } from 'react-router'
import { collections, isValidSegment } from 'shopify-payment-core'
import { SegmentKey, TRegistration } from 'shopify-payments-types'
import { useImmer } from 'use-immer'

import { Sentry } from '@speedlo/sentry'

import { useAuthEmail } from '../../hooks/useAuthEmail'
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction'
import { useQueryParams } from '../../hooks/useQueryParams'
import { RedirectToRoot } from '../../molecules/RedirectToRoot'
import { getFirebase } from '../../tools/firebase'
import { RegisterViewForm } from './components/RegisterViewForm'
import {
  TRegistrationFormValues,
  TRegistrationPhase,
} from './registerview.types'

type TProps = NoChildren

export const RegisterView: React.FC<TProps> = () => {
  const match = useRouteMatch<{ segmentKey: SegmentKey }>()
  const segmentKey = match && match.params.segmentKey

  const [state, updateState] = useImmer({
    phase: 'empty' as TRegistrationPhase,
    regId: null as MaybeID,
  })

  const [, setAuthEmail] = useAuthEmail()

  const { firestore } = getFirebase()
  React.useEffect(() => {
    if (!state.regId) {
      return
    }
    return firestore
      .collection(collections.register)
      .doc(state.regId)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) {
          return
        }
        const reg = snapshot.data() as TRegistration
        if (reg.status === 'pending') {
          setAuthEmail(reg.email)
          updateState(draft => {
            draft.phase = 'pending'
          })
        } else if (reg.status === 'email-failed') {
          updateState(draft => {
            draft.phase = 'email-failed'
          })
        }
      })
  }, [firestore, setAuthEmail, state.regId, updateState])

  const { getAllQueryParams } = useQueryParams()
  const meta = getAllQueryParams()

  const addRegistration = useFirebaseFunction('addRegistration')

  const onSubmit = React.useCallback(
    async ({ email, phone, shopName, newsletter }: TRegistrationFormValues) => {
      if (!segmentKey) {
        return
      }
      const reg = { email, shopName, phone, segmentKey, meta, newsletter }
      try {
        const { id, status } = await addRegistration(reg)
        updateState(draft => {
          draft.regId = id
          if (status === 'duplicate-account') {
            draft.phase = 'duplicate-account'
          } else if (status === 'has-user-account') {
            draft.phase = 'login-to-register'
          } else if (status === 'owned-account') {
            draft.phase = 'login-to-access'
          } else if (status === 'invalid-shop') {
            draft.phase = 'invalid-shop'
          } else {
            draft.phase = 'submitted'
          }
        })
      } catch (err) {
        Sentry.withScope(scope => {
          scope.setExtras(reg)
          Sentry.captureException(err)
        })
        console.error(err)
        updateState(draft => {
          draft.phase = 'unknown-error'
        })
      }
    },
    [addRegistration, meta, segmentKey, updateState],
  )

  if (!(segmentKey && isValidSegment(segmentKey))) {
    // ! Display selection of segment for registration
    return <RedirectToRoot />
  }

  return (
    <RegisterViewForm
      onSubmit={onSubmit}
      segmentKey={segmentKey}
      phase={state.phase}
      resetPhase={() => {
        updateState(draft => {
          draft.phase = 'empty'
        })
      }}
    />
  )
}
