import React from 'react'

import { collections } from 'shopify-payment-core'
import { TAccount, TAccountActivationStatus } from 'shopify-payments-types'

import { getFirebase } from '../../../tools/firebase'

export function useFreeAccountActivation(account: TAccount) {
  // const { isFree = false } = defaultConfig.segments[account.segmentKey]
  const isFree = true // make gateway free temporarilly
  const [isSubmitting, setSubmitting] = React.useState(false)

  const handleActivateFreeAccount = React.useCallback(async () => {
    if (!isFree) {
      return
    }
    const activationStatus: TAccountActivationStatus = 'active'

    setSubmitting(true)

    try {
      await getFirebase()
        .firestore.collection(collections.accounts)
        .doc(account.id)
        .update({ activationStatus })
    } finally {
      setSubmitting(false)
    }
  }, [account.id, isFree])

  return { isFree, isSubmitting, handleActivateFreeAccount }
}
