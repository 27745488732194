import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Card, CardContent } from '@material-ui/core'
import { useObjectValidation, XForm } from '@speedlo/xform'
import React from 'react'
import { collections } from 'shopify-payment-core'
import { TAccount, TUser } from 'shopify-payments-types'

import {
  useFirestore,
  useFirestoreDoc,
} from '../../../../hooks/createFirestore'
import { useFirebaseFunction } from '../../../../hooks/useFirebaseFunction'
import { useSnacks } from '../../../../hooks/useSnacks'
import { CardTitle } from '../../../../molecules/CardTitle'
import { FormInputField } from '../../../../molecules/forms/FormInputField'
import { FormSubmitButton } from '../../../../molecules/forms/FormSubmitButton'
import { GridItemSmall } from '../../../../molecules/GridItemSmall'
import { VerticalGap } from '../../../../molecules/VerticalGap'

type TProps = NoChildren & {
  account: TAccount
}

export const AccountDashboardOwnerAuthEmail: React.FC<TProps> = ({
  account,
}) => {
  const changeAuthEmail = useFirebaseFunction('changeAuthEmail')
  const { enqueueSuccess } = useSnacks()
  const { firestore } = useFirestore()

  const ownerRef = firestore.doc(`${collections.users}/${account.ownerId}`)
  const owner = useFirestoreDoc<TUser>(() => ownerRef)

  const onSubmit = React.useCallback(
    async values => {
      const update: Partial<TUser> = {
        email: values.email,
      }
      await changeAuthEmail({ uid: account.ownerId, newEmail: values.email })
      await ownerRef.update(update)
      enqueueSuccess(i18n._(t`Email změněn`))
    },
    [account, changeAuthEmail, enqueueSuccess, ownerRef],
  )

  const validationSchema = useObjectValidation(yup => ({
    email: yup
      .string()
      .required()
      .email(),
  }))

  return (
    <GridItemSmall>
      <Card>
        <CardTitle>
          <Trans>Změnit email vlastníka účtu</Trans>
        </CardTitle>
        <CardContent>
          <XForm
            initialValues={{ email: owner.email }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <FormInputField fieldName="email" fullWidth />
            <VerticalGap />
            <FormSubmitButton>
              <Trans>Provést změnu</Trans>
            </FormSubmitButton>
          </XForm>
        </CardContent>
      </Card>
    </GridItemSmall>
  )
}
