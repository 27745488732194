import { Typography } from '@material-ui/core'
import React from 'react'

type TProps = Required<SomeChildren>

export const ErrorMessage: React.FC<TProps> = ({ children }) => {
  return (
    <div>
      <Typography color="error">{children}</Typography>
    </div>
  )
}
