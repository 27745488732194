import { createMuiTheme, CssBaseline, MuiThemeProvider, Theme, useMediaQuery } from '@material-ui/core'
import React from 'react'

type TProps = Required<SomeChildren> & {
  forceMode?: TThemeMode
}

export type TThemeMode = 'dark' | 'light'

export const ThemeProvider: React.FC<TProps> = ({ children, forceMode }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: forceMode || (prefersDarkMode ? 'dark' : 'light'),
        },
        zIndex: {
          appBar: 1200,
          drawer: 1100,
        },
      }),
    [forceMode, prefersDarkMode],
  )

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}

export type TTheme = Theme & {}

export const withThemeProvider = storyFn => (
  <ThemeProvider>{storyFn()}</ThemeProvider>
)
