import { LinearProgress } from '@material-ui/core'
import { useSentryInit } from '@speedlo/sentry'
import React from 'react'
import { render } from 'react-dom'

import { App } from './app/App'
import { ErrorContainer } from './app/ErrorContainer'
import { I18nManager } from './app/I18nManager'
import { ThemeProvider } from './app/ThemeProvider'
import { appConfig } from './config'
import { GuestLayout } from './layouts/GuestLayout'

const Main: React.FC = () => {
  useSentryInit(appConfig.sentryDsn)

  return (
    <ThemeProvider>
      <React.Suspense
        fallback={
          <GuestLayout>
            <LinearProgress />
          </GuestLayout>
        }
      >
        <ErrorContainer>
          <I18nManager>
            <App />
          </I18nManager>
        </ErrorContainer>
      </React.Suspense>
    </ThemeProvider>
  )
}

const rootElement = document.getElementById('root')
render(<Main />, rootElement)
