import debug from 'debug'
import React from 'react'
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom'

import { ROUTE_LOGIN } from '../app/routes'

interface IRedirectToLoginProps {
  useOrigin?: boolean
}

const log = debug('routing')

export const RedirectToLogin: React.SFC<IRedirectToLoginProps> = ({
  useOrigin = true,
}) => {
  const location = useLocation()
  const match = useRouteMatch(ROUTE_LOGIN)
  if (match) {
    return null
  }
  log('redirecting to login...')
  return (
    <Redirect
      to={{
        pathname: ROUTE_LOGIN,
        state: { origin: useOrigin ? location.pathname : '/' },
      }}
    />
  )
}
