import React from 'react'

import { VerticalGap } from '../../../../molecules/VerticalGap'
import { AccountDashboardPaymentLabel } from '../dashboard/components/AccountDashboardPaymentLabel'
import { ConfirmoConfigCallbackPassword } from './ConfirmoConfigCallbackPassword'
import { ConfirmoConfigSettlementCurrency } from './ConfirmoConfigSettlementCurrency'

type TProps = NoChildren

export const ConfirmoConfig: React.FC<TProps> = props => {
  return (
    <>
      <ConfirmoConfigSettlementCurrency />
      <VerticalGap />
      <ConfirmoConfigCallbackPassword />
      <VerticalGap />
      <AccountDashboardPaymentLabel />
    </>
  )
}
