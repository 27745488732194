import { makeStyles, Typography } from '@material-ui/core'
import { Person } from '@material-ui/icons'
import { useObserver } from 'mobx-react-lite'
import React from 'react'

import { useAuth } from '../../../app/AuthProvider'

type TProps = NoChildren

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    margin: theme.spacing(1),
  },
}))

export const UserName: React.FC<TProps> = () => {
  const classes = useStyles()
  const authModel = useAuth()
  return useObserver(() => (
    <div className={classes.root}>
      <Person />
      <Typography variant="subtitle2" className={classes.name}>
        {authModel.name}
      </Typography>
    </div>
  ))
}
