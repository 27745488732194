import React from 'react'

import { segmentLabels } from 'shopify-payment-core'
import { ComGate, Confirmo, GoPay, TAccount } from 'shopify-payments-types'

import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Card, CardContent, Typography } from '@material-ui/core'

import { useSnacks } from '../../../../hooks/useSnacks'
import { CardTitle } from '../../../../molecules/CardTitle'
import { GridItemSmall } from '../../../../molecules/GridItemSmall'
import { useAccountActivation } from '../../hooks/useAccountActivation'
import { ComGateCredentials } from '../comgate/ComGateCredentials'
import { ConfirmoCredentials } from '../confirmo/ConfirmoCredentials'
import { GoPayCredentials } from '../gopay/GoPayCredentials'

type TProps = NoChildren & {
  account: TAccount
}

export const AccountDashboardSegmentCredentials: React.FC<TProps> = ({
  account,
}) => {
  const {
    handleCredentialsUpdate,
    handleVerifyCredentials,
  } = useAccountActivation(account)

  const { enqueueError, enqueueSuccess } = useSnacks()

  const verifyAndUpdateOnSuccess = async credentials => {
    if ((await handleVerifyCredentials(credentials)) === 'verified') {
      await handleCredentialsUpdate()
      enqueueSuccess(i18n._(t`Nové údaje byly úspěšně oveřeny a uloženy.`))
    } else {
      enqueueError(
        i18n._(
          t`Nové údaje se nepodařilo ověřit, zkontrolujete prosím zadání.`,
        ),
      )
    }
  }

  const credentials = (() => {
    switch (account.segmentKey) {
      case 'gopay':
        return (
          <GoPayCredentials
            credentials={
              account.segmentCredentials as GoPay.TSegmentCredentials
            }
            isVerified={false}
            onSubmit={verifyAndUpdateOnSuccess}
          />
        )
      case 'comgate':
        return (
          <ComGateCredentials
            credentials={
              account.segmentCredentials as ComGate.TSegmentCredentials
            }
            isVerified={false}
            onSubmit={verifyAndUpdateOnSuccess}
          />
        )
      case 'confirmo':
        return (
          <ConfirmoCredentials
            credentials={
              account.segmentCredentials as Confirmo.TSegmentCredentials
            }
            isVerified={false}
            onSubmit={verifyAndUpdateOnSuccess}
          />
        )
    }
  })()

  const segmentLabel = segmentLabels[account.segmentKey]

  return (
    <GridItemSmall>
      <Card>
        <CardTitle>
          <Trans>Údaje platební brány</Trans>
        </CardTitle>
        <CardContent>
          <Typography gutterBottom>
            <Trans>
              Máte potíže s prováděním plateb přes {segmentLabel}? Zkontrolujte
              a případně opravte údaje.
            </Trans>
          </Typography>
          {credentials}
        </CardContent>
      </Card>
    </GridItemSmall>
  )
}
