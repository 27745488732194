import React from 'react'

import { VerticalGap } from '../../../../molecules/VerticalGap'
import { AccountDashboardLanguagePicker } from '../dashboard/components/AccountDashboardLanguagePicker'
import { AccountDashboardPaymentLabel } from '../dashboard/components/AccountDashboardPaymentLabel'
import { GoPayConfigPaymentInstruments } from './GoPayConfigPaymentInstruments'

type TProps = NoChildren

export const GoPayConfig: React.FC<TProps> = props => {
  return (
    <>
      <GoPayConfigPaymentInstruments />
      <VerticalGap />
      <AccountDashboardLanguagePicker languages={getLanguages()} />
      <VerticalGap />
      <AccountDashboardPaymentLabel />
    </>
  )
}

function getLanguages() {
  return [
    { value: 'CS', label: 'Čeština' },
    { value: 'EN', label: 'Angličtina' },
    { value: 'SK', label: 'Slovenština' },
    { value: 'DE', label: 'Němčina' },
    { value: 'RU', label: 'Ruština' },
    { value: 'PL', label: 'Polština' },
    { value: 'HU', label: 'Maďarština' },
    { value: 'FR', label: 'Francouzština' },
    { value: 'RO', label: 'Rumunština' },
    { value: 'BG', label: 'Bulharština' },
    { value: 'HR', label: 'Chorvatština' },
    { value: 'IT', label: 'Italština    ' },
  ]
}
