import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import React from 'react'

import { activateLocale, detectLocale } from '../lib/lingui'
import { createSimpleSuspense } from '../tools/suspense'

type TProps = Required<SomeChildren>

const supportedLocales = ['cs', 'en']
const detectedLocale = detectLocale(supportedLocales)

const suspend = createSimpleSuspense(activateLocale(detectedLocale))

export const I18nManager: React.FC<TProps> = ({ children }) => {
  suspend()
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
