import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { collections } from 'shopify-payment-core'
import { TAccount } from 'shopify-payments-types'

import { useFirestoreDoc } from '../../hooks/createFirestore'
import { AccountViewActivation } from './components/AccountViewActivation'
import { AccountViewDashboard } from './components/AccountViewDashboard'
import { AccountViewInstallation } from './components/AccountViewInstallation'

type TProps = NoChildren

export const AccountView: React.FC<TProps> = () => {
  const match = useRouteMatch<{ id: ID }>()!
  const account = useFirestoreDoc<TAccount>(db =>
    db.collection(collections.accounts).doc(match.params.id),
  )
  switch (account.activationStatus) {
    case 'new':
      return <AccountViewActivation account={account} />
    case 'verified':
    case 'unpaid':
      return <AccountViewInstallation account={account} />
    default:
      return <AccountViewDashboard account={account} />
  }
}
