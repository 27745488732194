import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import React from 'react'

import { usePageTitle } from '../../../hooks/usePageTitle'
import { ProgressMessage } from '../../../molecules/ProgressMessage'

type TProps = NoChildren & {
  isRegistration: boolean
}

export const AuthViewContinue: React.FC<TProps> = ({ isRegistration }) => {
  usePageTitle(
    isRegistration
      ? i18n._(t`Pokračování registrace`)
      : i18n._(t`Přihlašování`),
  )

  return (
    <ProgressMessage>
      {isRegistration ? (
        <Trans>
          Za malý okamžik dokončíme vytvoření vašeho uživatelského účtu.
        </Trans>
      ) : (
        <Trans>Ověřujeme přihlášení k vašemu účtu.</Trans>
      )}
      <br />
      <Trans>Prosíme o strpení...</Trans>
    </ProgressMessage>
  )
}
