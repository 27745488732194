import React from 'react'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'

import { GuestRoute } from '../molecules/GuestRoute'
import { SecureRoute } from '../molecules/SecureRoute'
import AccountView from '../views/AccountView'
import AddAccountView from '../views/AddAccountView'
import AdminReferralsView from '../views/AdminReferralsView'
import AdminView from '../views/AdminView'
import AuthView from '../views/AuthView'
import LoginView from '../views/LoginView'
import { LogoutView } from '../views/LogoutView'
import { NotFoundView } from '../views/NotFoundView'
import { RegisterFinishView } from '../views/RegisterFinishView'
import RegisterView from '../views/RegisterView'
import UserView from '../views/UserView'
import {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_ADD,
  ROUTE_ADMIN,
  ROUTE_ADMIN_REFERRALS,
  ROUTE_AUTH,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_REGISTER,
  ROUTE_REGISTER_FINISH,
  ROUTE_ROOT,
} from './routes'

export const Routing = () => (
  <Switch>
    <SecureRoute path={ROUTE_ROOT} exact view={UserView} />
    <SecureRoute path={`${ROUTE_ACCOUNT}/:id`} view={AccountView} />
    <SecureRoute
      path={`${ROUTE_ACCOUNT_ADD}/:segmentKey`}
      view={AddAccountView}
    />
    <SecureRoute path={ROUTE_ADMIN} exact view={AdminView} adminOnly />
    <SecureRoute
      path={ROUTE_ADMIN_REFERRALS}
      view={AdminReferralsView}
      adminOnly
    />
    <GuestRoute path={`${ROUTE_REGISTER}/:segmentKey`} view={RegisterView} />
    <GuestRoute
      path={`${ROUTE_REGISTER_FINISH}/:registrationId`}
      view={RegisterFinishView}
    />
    <GuestRoute path={ROUTE_LOGIN} view={LoginView} />
    <GuestRoute path={ROUTE_LOGOUT} view={LogoutView} />
    <GuestRoute path={ROUTE_AUTH} view={AuthView} />
    <Route
      path={ROUTE_REGISTER}
      exact
      children={() => {
        window.location.assign(
          'https://podnikat.online/pages/gopay-a-comgate-na-shopify',
        )
      }}
    />
    <GuestRoute view={NotFoundView} />
  </Switch>
)
