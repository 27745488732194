import { Trans } from '@lingui/macro'
import { Link, Typography } from '@material-ui/core'
import React from 'react'

import { ROUTE_REGISTER } from '../../../app/routes'
import { ContactLink } from '../../../molecules/ContactButton'
import { TLoginStatus } from '../loginview.types'

type TProps = NoChildren & {
  status: TLoginStatus
}

export const LoginViewStatus: React.FC<TProps> = ({ status }) => {
  if (status === 'error') {
    return (
      <Typography color="error">
        <br />
        <Trans>
          Při odesílání emailu došlo k neznámé chybě. Prosím, <ContactLink />{' '}
          pokud problém přetrvává.
        </Trans>
      </Typography>
    )
  }

  if (status === 'not-found') {
    return (
      <Typography color="error">
        <br />
        <Trans>
          Pro zadaný email účet neexistuje. Chcete se místo toho{' '}
          <Link href={ROUTE_REGISTER} color="textSecondary">
            registrovat
          </Link>
          ?
        </Trans>
      </Typography>
    )
  }

  if (status === 'sent') {
    return (
      <Typography color="textPrimary">
        <br />
        <Trans>
          Email odeslán. Toto okno můžete nyní zavřít a použít odkaz z emailu.
        </Trans>
      </Typography>
    )
  }

  return null
}
