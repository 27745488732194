import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import React from 'react'

type TProps = NoChildren

export const ConfirmoInstallationInfo: React.FC<TProps> = props => {
  return (
    <>
      <Typography paragraph>
        <Trans>
          Ujistěte se, že máte údaje správně zadané. K tomuto kroku se již
          později nelze vrátit.
        </Trans>
      </Typography>
    </>
  )
}
