import { observer } from 'mobx-react-lite'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { appConfig } from '../config'
import { loadIntercom, TIntercom } from '../lib/intercom'
import { TAuthModel } from '../models/AuthModel'
import { useAuth } from './AuthProvider'
import { ROUTE_ACCOUNT } from './routes'

type TProps = NoChildren

export const Intercom: React.FC<TProps> = () => {
  const auth = useAuth()
  return <IntercomInternal auth={auth} />
}

const IntercomInternal = observer<{ auth: TAuthModel }>(({ auth }) => {
  const match = useRouteMatch<{ id: ID }>(`${ROUTE_ACCOUNT}/:id`)

  const userId = auth.uid
  const email = auth.user?.email
  const name = match?.params.id

  const intercomRef = React.useRef<TIntercom>()
  const [intercomLoaded, setIntercomLoaded] = React.useState(false)

  React.useEffect(() => {
    loadIntercom(appConfig.intercomAppID)
      .then(Intercom => {
        intercomRef.current = Intercom
        setIntercomLoaded(true)
      })
      .catch(console.error)
  }, [])

  React.useEffect(() => {
    if (intercomLoaded) {
      intercomRef.current?.('boot', {
        app_id: appConfig.intercomAppID,
        user_id: userId,
        email,
        name,
      })
    }
    return () => {
      intercomRef.current?.('shutdown')
    }
  }, [email, intercomLoaded, name, userId])

  return null
})
