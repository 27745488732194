import { CircularProgress, Fade, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
})

export const PageLoader: React.FC<NoChildren> = () => {
  const classes = useStyles()
  return (
    <Fade in appear>
      <div className={classes.root} role="banner">
        <CircularProgress color="primary" />
      </div>
    </Fade>
  )
}
