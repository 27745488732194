import { format, formatWithOptions } from 'date-fns/fp'
import csLocale from 'date-fns/locale/cs'

export const localeOptions = { locale: csLocale }

export const formatLocale = formatWithOptions(localeOptions)

export const formatDateInput = format('YYYY-MM-DD')

export const formatDate = formatLocale('PPP')
