import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { useObjectValidation, XForm } from '@speedlo/xform'
import React from 'react'

import { FormInputField } from './forms/FormInputField'
import { FormSubmitButton } from './forms/FormSubmitButton'
import { VerticalGap } from './VerticalGap'

type TProps = NoChildren & {
  buttonText: ReactNode
  onSubmit(email: string): Promise<void>
}

export const LoginForm: React.FC<TProps> = ({ onSubmit, buttonText }) => {
  const validationSchema = useObjectValidation(yup => ({
    email: yup
      .string()
      .required()
      .email(),
  }))
  return (
    <XForm
      initialValues={{ email: '' }}
      validationSchema={
        process.env.NODE_ENV === 'development' ? undefined : validationSchema
      }
      onSubmit={values => {
        return onSubmit(values.email)
      }}
    >
      <FormInputField
        fieldName="email"
        label={i18n._(t`Email`)}
        type={process.env.NODE_ENV === 'development' ? 'text' : 'email'}
        required
        fullWidth
      />
      <VerticalGap />
      <FormSubmitButton submitNode={<Trans>Posíláme email...</Trans>}>
        {buttonText}
      </FormSubmitButton>
    </XForm>
  )
}
