import React from 'react'

import _ from 'lodash'

import { getFirebase } from '../tools/firebase'
import { idPropEq } from '../tools/ramda'
import { createSuspense } from '../tools/suspense'

const firestoreContext = React.createContext(createFirestore())

type TCacheApi<T> = {
  ref: TReferences
  read(): T
  clear(): void
  watch(cb: TWatchCallback): void
  onCreate(cb: () => void): void
  onSnapshot(snapshot: unknown): void
  onError(error: Error): void
}

type TWatchCallback = () => void

type TDocumentRef = firebase.firestore.DocumentReference
type TReferences = TDocumentRef | TCollectionRef
type TCollectionRef =
  | firebase.firestore.CollectionReference
  | firebase.firestore.Query

function createFirestore() {
  const suspense = createSuspense()

  const cache = {}
  const getFromCacheOrCreate = (key: string, onCreate: TWatchCallback) => {
    if (!cache[key]) {
      return (cache[key] = onCreate())
    }
    return cache[key]
  }

  function getForDocument<TDocument>(ref: TDocumentRef): TCacheApi<TDocument> {
    return getFromCacheOrCreate(ref.path, () => {
      const { read, setSuccess, setFailure } = suspense.getCache<TDocument>(
        ref.path,
      )

      let watchCb: TWatchCallback = _.noop
      const watch = (cb: TWatchCallback) => {
        watchCb = cb
      }

      const onSnapshot = (snapshot: firebase.firestore.DocumentSnapshot) => {
        if (snapshot.exists) {
          const doc = snapshot.data() as TDocument
          setSuccess(doc, watchCb)
        } else {
          setFailure(new DocumentNotFoundError(ref))
        }
      }

      const onError = (error: Error) => {
        setFailure(error, watchCb)
      }

      const stop = ref.onSnapshot(onSnapshot, onError)
      const clear = () => {
        stop()
        cache[ref.path] = undefined
      }

      return {
        ref,
        read,
        watch,
        clear,
        onSnapshot,
        onError,
      }
    })
  }

  function getForCollection<TDocument>(
    ref: TCollectionRef,
    key: string,
  ): TCacheApi<RoA<TDocument>> {
    return getFromCacheOrCreate(key, () => {
      const { read, setSuccess, setFailure } = suspense.getCache<
        RoA<TDocument>
      >(key)

      let watchCb: TWatchCallback = _.noop
      const watch = (cb: TWatchCallback) => {
        watchCb = cb
      }

      const docs: TDocument[] = []

      const onSnapshot = (snapshot: firebase.firestore.QuerySnapshot) => {
        if (snapshot.empty) {
          setSuccess(docs, watchCb)
          return
        }

        snapshot.docChanges().forEach(({ doc, type }) => {
          const id = doc.id
          const foundIdx = docs.findIndex(idPropEq(id))
          if (foundIdx === -1) {
            docs.push(doc.data() as TDocument)
          } else {
            if (type === 'modified') {
              docs[foundIdx] = doc.data() as TDocument
            } else if (type === 'removed') {
              docs.splice(foundIdx, 1)
            }
          }
        })

        setSuccess(docs, watchCb)
      }

      const onError = (error: Error) => {
        setFailure(error, watchCb)
      }

      const stop = ref.onSnapshot(onSnapshot, onError)
      const clear = () => {
        stop()
        cache[key] = undefined
      }

      return {
        ref,
        read,
        watch,
        clear,
        onSnapshot,
        onError,
      }
    })
  }

  const { firestore } = getFirebase()

  return {
    firestore,
    getForDocument,
    getForCollection,
  }
}

export function useFirestore() {
  return React.useContext(firestoreContext)
}

export function useFirestoreDoc<TDocument extends Dictionary>(
  getRef: (firestore: firebase.firestore.Firestore) => TDocumentRef,
) {
  const forceUpdate = useForceUpdate()
  const { firestore, getForDocument } = React.useContext(firestoreContext)

  const ref = getRef(firestore)
  const cache = getForDocument<TDocument>(ref)

  React.useEffect(() => {
    if (!ref.isEqual(cache.ref as TDocumentRef)) {
      cache.clear()
    }
  })

  cache.watch(forceUpdate)
  return cache.read()
}

export function useFirestoreQuery<TDocument extends Dictionary>(
  queryKey: string,
  getRef: (firestore: firebase.firestore.Firestore) => TCollectionRef,
) {
  const forceUpdate = useForceUpdate()
  const { firestore, getForCollection } = React.useContext(firestoreContext)

  const ref = getRef(firestore)
  const cache = getForCollection<TDocument>(ref, queryKey)

  React.useEffect(() => {
    if (!ref.isEqual(cache.ref as any)) {
      cache.clear()
    }
  })

  cache.watch(forceUpdate)
  return cache.read()
}

function useForceUpdate() {
  const [, dispatch] = React.useState(0)
  return () => dispatch(i => i + 1)
}

// function isDocumentReference(ref: TReferences): ref is TDocumentRef {
//   return Reflect.has(ref, 'parent')
// }

// function isCollectionReference(ref: TReferences): ref is TCollectionRef {
//   return Reflect.has(ref, 'where')
// }

export class DocumentNotFoundError extends Error {
  readonly ref: TDocumentRef
  constructor(ref: TDocumentRef) {
    super(`Document ${ref.path} does not exists`)
    this.ref = ref
  }
}
