import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import { useXField, useXFormState, WithXField } from '@speedlo/xform'
import { observer } from 'mobx-react-lite'
import React from 'react'

type TProps = NoChildren &
  WithXField &
  CheckboxProps & {
    label: ReactNode
  }

export const FormCheckboxField = observer<TProps>(
  ({ fieldName, label, required, ...checkboxProps }) => {
    const field = useXField<boolean>(fieldName)
    const state = useXFormState()
    const box = (
      <Checkbox
        checked={field.value}
        disabled={state.isSubmitting}
        onChange={(ev, checked) => field.setValue(checked)}
        onBlur={() => field.setTouched()}
        id={field.name}
        name={field.name}
        {...checkboxProps}
      />
    )
    return (
      <FormControl error={field.hasError} required={required}>
        <FormControlLabel label={label} control={box} />
      </FormControl>
    )
  },
)
