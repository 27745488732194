import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import React from 'react'

import { usePageTitle } from '../../../hooks/usePageTitle'
import { LoginForm } from '../../../molecules/LoginForm'

type TProps = NoChildren & {
  isRegistration: boolean
  onSubmit(email: string): Promise<void>
}

export const AuthViewVerify: React.FC<TProps> = ({
  isRegistration,
  onSubmit,
}) => {
  usePageTitle(
    isRegistration
      ? i18n._(t`Potvrzeni registrace`)
      : i18n._(t`Potvrzení přihlášení`),
  )

  return (
    <>
      <Typography paragraph>
        <Trans>
          Zdá se, že jste odkaz použili v jiném prohlížeči.
          <br />
          Prosím vyplňte znovu stejnou emailovou adresu.
        </Trans>
      </Typography>
      <LoginForm onSubmit={onSubmit} buttonText={<Trans>Pokračovat</Trans>} />
    </>
  )
}
