import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@material-ui/core'
import { useXField } from '@speedlo/xform'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { getGoPayEnabledInstrumentsByDefault } from 'shopify-payment-core'
import { GoPay } from 'shopify-payments-types'

import { useSnacks } from '../../../../hooks/useSnacks'
import { FormDropdownField } from '../../../../molecules/forms/FormDropdownField'
import { VerticalGap } from '../../../../molecules/VerticalGap'
import { RM } from '../../../../tools/ramda'
import { GoPayConfigPayPal } from './GoPayConfigPayPal'

type TProps = NoChildren

const defaultInstruments = getGoPayEnabledInstrumentsByDefault()

export const GoPayConfigPaymentInstruments = observer<TProps>(() => {
  const { enqueueWarning } = useSnacks()
  const enabledField = useXField<GoPay.TPaymentInstrument[]>(
    'enabledPaymentInstruments',
  )

  const isEnabled = (value: GoPay.TPaymentInstrument) =>
    (enabledField.value ?? defaultInstruments).includes(value)

  const renderInstrument = ({ label, value }: TInstrument) => {
    return (
      <FormControlLabel
        key={value}
        label={label}
        control={
          <Switch
            value={value}
            checked={isEnabled(value)}
            onChange={() => {
              if (isEnabled(value)) {
                if (enabledField.value?.length === 1) {
                  enqueueWarning(
                    i18n._(
                      t`Minimálně jedna platební metoda musí být povolena.`,
                    ),
                  )
                  return
                }
                enabledField.setValue(
                  RM.without([value], enabledField.value ?? defaultInstruments),
                )
              } else if (enabledField.value) {
                enabledField.value.push(value)
              } else {
                enabledField.setValue([value])
              }
            }}
          />
        }
      />
    )
  }

  const instruments = getInstruments()
  const enabledInstruments = Array.isArray(enabledField.value)
    ? instruments.filter(({ value }) => enabledField.value.includes(value))
    : instruments

  return (
    <>
      <Typography>
        <Trans>Povolené platební metody</Trans>
      </Typography>
      <FormControl component="fieldset">
        <FormGroup row>{instruments.map(renderInstrument)}</FormGroup>
      </FormControl>
      <VerticalGap />
      {isEnabled('PAYPAL') && <GoPayConfigPayPal />}
      <FormDropdownField
        fieldName="defaultPaymentInstrument"
        emptyLabel={
          <Trans>
            <i>Zákazník si vybere</i>
          </Trans>
        }
        options={enabledInstruments}
        label={i18n._(t`Výchozí platební metoda`)}
      />
    </>
  )
})

type TInstrument = {
  label: string
  value: GoPay.TPaymentInstrument
}

function getInstruments(): RoA<TInstrument> {
  return [
    {
      label: `Google Pay`,
      value: 'GPAY',
    },
    {
      label: i18n._(t`Platba kartou`),
      value: 'PAYMENT_CARD',
    },
    {
      label: i18n._(t`Bankovní převod`),
      value: 'BANK_ACCOUNT',
    },
    {
      label: `PayPal`,
      value: 'PAYPAL',
    },
    {
      label: `Apple Pay`,
      value: 'APPLE_PAY',
    },
  ]
}
