import React from 'react'
import { Redirect } from 'react-router-dom'

import { ROUTE_ROOT } from '../app/routes'

type TProps = NoChildren

export const RedirectToRoot: React.FC<TProps> = props => {
  return <Redirect to={ROUTE_ROOT} />
}
