import { Trans } from '@lingui/macro'
import { Card, CardContent, Typography } from '@material-ui/core'
import React from 'react'
import { TAccount } from 'shopify-payments-types'

import { CardTitle } from '../../../../molecules/CardTitle'
import { GridItemSmall } from '../../../../molecules/GridItemSmall'
import { parseISO } from 'date-fns'
import { formatDate } from '../../../../tools/formats'
import { ExternalLink } from '../../../../molecules/ExternalLink'

type TProps = NoChildren & {
  account: TAccount
}

export const AccountDashboardPaddle: React.FC<TProps> = ({ account }) => {
  if (!account.paddle) {
    return null
  }

  const { createdAt, invoiceUrl } = account.paddle

  const formattedPaymentDate = formatDate(parseISO(createdAt))

  return (
    <GridItemSmall>
      <Card>
        <CardTitle>
          <Trans>Informace o platbě</Trans>
        </CardTitle>
        <CardContent>
          <Typography gutterBottom>
            <Trans>
              Uhrazeno: <b>{formattedPaymentDate}</b>
            </Trans>
          </Typography>
          <ExternalLink href={invoiceUrl}>
            <Trans>Zobrazit fakturu</Trans>
          </ExternalLink>
        </CardContent>
      </Card>
    </GridItemSmall>
  )
}
