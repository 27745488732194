import React from 'react'

const key = `sgm@prod_authEmail`

export function useAuthEmail() {
  const emailRef = React.useRef<Maybe<string>>(null)

  const setAuthEmail = React.useCallback((email: Maybe<string>) => {
    emailRef.current = email
    if (email === null) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, email)
    }
  }, [])

  if (emailRef.current === null) {
    emailRef.current = localStorage.getItem(key)
  }

  return [emailRef.current, setAuthEmail] as [
    Maybe<string>,
    typeof setAuthEmail,
  ]
}
