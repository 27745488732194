import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core'
import { useXField, WithXField, XFieldValidator } from '@speedlo/xform'
import { useObserver } from 'mobx-react-lite'
import React from 'react'

import { RM } from '../../tools/ramda'

type TProps<T> = NoChildren &
  WithXField & {
    options: RoA<T>
    getValue?: (option: T) => string | number
    getLabel?: (option: T) => string | ReactNode
    emptyLabel?: ReactNode
    emptyValue?: any
    validate?: XFieldValidator<T>
  } & Omit<SelectProps, 'validate'>

export function FormDropdownField<T extends Dictionary>({
  fieldName,
  options,
  getValue = RM.prop('value'),
  getLabel = RM.prop('label'),
  label,
  defaultValue,
  emptyLabel,
  emptyValue = null,
  validate,
  ...selectProps
}: TProps<T>) {
  const field = useXField<T>(fieldName, { validate })

  return useObserver(() => (
    <FormControl fullWidth>
      <InputLabel id={fieldName as string} shrink>
        {label}
      </InputLabel>
      <Select
        error={field.hasError}
        value={field.value ?? ''}
        onChange={ev => {
          const value = ev.target.value
          field.setValue(value === '' ? emptyValue : value)
        }}
        displayEmpty={!!emptyLabel}
        labelId={fieldName as string}
        fullWidth
        {...selectProps}
      >
        {emptyLabel && <MenuItem value="">{emptyLabel}</MenuItem>}
        {options.map(option => (
          <MenuItem key={getValue(option)} value={getValue(option)}>
            {getLabel(option)}
          </MenuItem>
        ))}
      </Select>
      {field.hasError && <FormHelperText error>{field.error}</FormHelperText>}
    </FormControl>
  ))
}
