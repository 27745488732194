import { Trans } from '@lingui/macro'
import React from 'react'
import { TAccountActivationStatus } from 'shopify-payments-types'

type TProps = NoChildren & {
  status: TAccountActivationStatus
}

export const AccountStatusLabel: React.FC<TProps> = ({ status }) => {
  switch (status) {
    case 'active':
      return <Trans>aktivní</Trans>
    case 'unpaid':
      return <Trans>neuhrazeno</Trans>
    case 'verified':
      return <Trans>ověřeno</Trans>
    case 'new':
      return <Trans>nový</Trans>
    case 'blocked':
      return <Trans>blokovaný</Trans>
    default:
      return <Trans>neznámý</Trans>
  }
}
