import { ButtonProps } from '@material-ui/core'
import { useXFormState } from '@speedlo/xform'
import { useObserver } from 'mobx-react-lite'
import React from 'react'

import { SubmitButton } from '../SubmitButton'

type TProps = Required<SomeChildren> &
  ButtonProps & {
    submitNode?: ReactNode
  }

export const FormSubmitButton: React.FC<TProps> = ({
  children,
  submitNode = children,
  ...buttonProps
}) => {
  const state = useXFormState()
  return useObserver(() => (
    <SubmitButton
      isSubmitting={state.isSubmitting}
      disabled={!state.isValid || state.isSubmitting}
      {...buttonProps}
    >
      {state.isSubmitting ? submitNode : children}
    </SubmitButton>
  ))
}
