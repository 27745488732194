export const ROUTE_ROOT = '/'
export const ROUTE_LOGIN = `${ROUTE_ROOT}login`
export const ROUTE_LOGOUT = `${ROUTE_ROOT}logout`
export const ROUTE_AUTH = `${ROUTE_ROOT}auth`
export const ROUTE_REGISTER = `${ROUTE_ROOT}register`
export const ROUTE_REGISTER_FINISH = `${ROUTE_ROOT}finish`
export const ROUTE_ACCOUNT = `${ROUTE_ROOT}account`
export const ROUTE_ACCOUNT_ADD = `${ROUTE_ROOT}add`
export const ROUTE_ADMIN = `${ROUTE_ROOT}admin`
export const ROUTE_ADMIN_REFERRALS = `${ROUTE_ADMIN}/refs`
