import React from 'react'
import { collections } from 'shopify-payment-core'
import { TRegistration } from 'shopify-payments-types'

import { useFirestoreQuery } from '../../hooks/createFirestore'
import { AdminReferralsTable } from './components/AdminReferralsTable'

type TProps = NoChildren

export const AdminReferralsView: React.FC<TProps> = () => {
  const registrations = useFirestoreQuery<TRegistration>('registrations', db =>
    db
      .collection(collections.register)
      .where('meta.utm_source', '>', '')
      .where('status', '==', 'success'),
  )
  return <AdminReferralsTable registrations={registrations} />
}
