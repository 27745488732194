import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Card, CardContent } from '@material-ui/core'
import { XForm } from '@speedlo/xform'
import React from 'react'
import { collections } from 'shopify-payment-core'
import { TAccount } from 'shopify-payments-types'

import { useFirestore } from '../../../../hooks/createFirestore'
import { useSnacks } from '../../../../hooks/useSnacks'
import { CardTitle } from '../../../../molecules/CardTitle'
import { FormInputField } from '../../../../molecules/forms/FormInputField'
import { FormSubmitButton } from '../../../../molecules/forms/FormSubmitButton'
import { GridItemSmall } from '../../../../molecules/GridItemSmall'
import { VerticalGap } from '../../../../molecules/VerticalGap'

type TProps = NoChildren & {
  account: TAccount
}

export const AccountDashboardAdminNote: React.FC<TProps> = ({ account }) => {
  const { enqueueSuccess } = useSnacks()
  const { firestore } = useFirestore()
  const onSubmit = React.useCallback(
    async values => {
      await firestore
        .doc(`${collections.accounts}/${account.id}`)
        .update({ adminNote: values.note })
      enqueueSuccess(i18n._(t`Poznámka uložena`))
    },
    [account, enqueueSuccess, firestore],
  )

  return (
    <GridItemSmall>
      <Card>
        <CardTitle>
          <Trans>Poznámka administrátora</Trans>
        </CardTitle>
        <CardContent>
          <XForm
            initialValues={{ note: account.adminNote }}
            onSubmit={onSubmit}
          >
            <FormInputField
              fieldName="note"
              fullWidth
              multiline
              rows={8}
              trimOnChange={false}
            />
            <VerticalGap />
            <FormSubmitButton>
              <Trans>Uložit poznámku</Trans>
            </FormSubmitButton>
          </XForm>
        </CardContent>
      </Card>
    </GridItemSmall>
  )
}
