import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { Tooltip } from '@material-ui/core'
import { FileCopyOutlined } from '@material-ui/icons'
import React from 'react'
import Clipboard from 'react-clipboard.js'

import { useSnacks } from '../hooks/useSnacks'

type TProps = NoChildren & {
  text: string
  className?: string
}

export const CopyToClipboard: React.FC<TProps> = ({ text, className }) => {
  const { enqueueInfo } = useSnacks()
  return (
    <Clipboard
      className={className}
      data-clipboard-text={text}
      component="span"
      onSuccess={() => enqueueInfo(i18n._(t`Zkopírováno`))}
    >
      <Tooltip title={i18n._(t`Zkopírovat do schránky`)}>
        <FileCopyOutlined />
      </Tooltip>
    </Clipboard>
  )
}
