import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import React from 'react'

type TProps = NoChildren

export const UserView: React.FC<TProps> = () => {
  return (
    <Typography>
      <Trans>Zvolte obchod v levém panelu</Trans>
    </Typography>
  )
}
