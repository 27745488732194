import React from 'react'

import { Confirmo } from 'shopify-payments-types'

import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'

import { ExternalLink } from '../../../../molecules/ExternalLink'
import { TActivationResult, TActivationStatus } from '../../accountview.types'
import { AccountViewActivationCredentials } from '../AccountViewActivationCredentials'
import { AccountViewActivationIntro } from '../AccountViewActivationIntro'
import { TConfirmoCredentialsForm } from './confirmo.types'
import { ConfirmoActivationRegister } from './ConfirmoActivationRegister'
import { ConfirmoCredentials } from './ConfirmoCredentials'

type TProps = NoChildren & {
  account: Confirmo.TSegmentAccount
  activationResult: TActivationResult
  handleVerifyCredentials(
    credentials: TConfirmoCredentialsForm,
  ): Promise<TActivationStatus>
  handleVerifyConfirm(): void
}

export const ConfirmoActivationLayout: React.FC<TProps> = ({
  account,
  handleVerifyCredentials,
  handleVerifyConfirm,
  activationResult,
}) => {
  const [showHint, setShowHint] = React.useState(false)
  return (
    <>
      <AccountViewActivationIntro account={account} />
      <Typography>
        <Trans>
          V nastavení Vašeho Confirmo účtu je potřeba přidat{' '}
          <ExternalLink
            href="https://confirmo.net/platform/settings/api-keys"
            noIcon
          >
            API klíč
          </ExternalLink>{' '}
          a tento zkopírovat do pole níže.
        </Trans>
      </Typography>
      <AccountViewActivationCredentials
        activationResult={activationResult}
        handleVerifyConfirm={handleVerifyConfirm}
        errorMessage={<Trans>Zadaný klíč není platný</Trans>}
      >
        <ConfirmoCredentials
          key={activationResult.status}
          isVerified={activationResult.status === 'verified'}
          credentials={account.segmentCredentials}
          onSubmit={handleVerifyCredentials}
          onRegHintClick={() => setShowHint(s => !s)}
        />
      </AccountViewActivationCredentials>
      {account.segmentCredentials.verifiedAt === null && (
        <ConfirmoActivationRegister show={showHint} />
      )}
    </>
  )
}
