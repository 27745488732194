import { CardHeader, makeStyles, Typography } from '@material-ui/core'
import { CardHeaderProps } from '@material-ui/core/CardHeader'
import React from 'react'

type TProps = Required<SomeChildren> &
  Omit<CardHeaderProps, 'title' | 'subtitle'>

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2, 0, 2),
  },
}))

export const CardTitle: React.FC<TProps> = ({
  children,
  ...cardHeaderProps
}) => {
  const classes = useStyles()
  return (
    <CardHeader
      {...cardHeaderProps}
      classes={{
        root: classes.root,
      }}
      title={
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
          style={{ textTransform: 'uppercase' }}
        >
          {children}
        </Typography>
      }
    ></CardHeader>
  )
}
