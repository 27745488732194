import { t, Trans } from '@lingui/macro'
import { Card, CardContent, Typography } from '@material-ui/core'
import { useMachine } from '@xstate/react'
import React from 'react'

import { useFirebaseFunction } from '../../../../hooks/useFirebaseFunction'
import { useSnacks } from '../../../../hooks/useSnacks'
import { AdminOnly } from '../../../../molecules/AdminOnly'
import { CardTitle } from '../../../../molecules/CardTitle'
import { GridItemSmall } from '../../../../molecules/GridItemSmall'
import { SubmitButton } from '../../../../molecules/SubmitButton'
import { VerticalGap } from '../../../../molecules/VerticalGap'
import { newsletterMachine } from './machinery/newsletterMachine'

type TProps = NoChildren

export const AccountDashboardNewsletter: React.FC<TProps> = () => {
  const { enqueueSuccess, enqueueError } = useSnacks()
  const newsletterStatus = useFirebaseFunction('newsletterStatus')
  const newsletterUpdate = useFirebaseFunction('newsletterUpdate')

  const [current, send] = useMachine(newsletterMachine, {
    services: {
      getStatus: () => newsletterStatus({}),
      subscribe: () => newsletterUpdate({ action: 'subscribe' }),
      unsubscribe: () => newsletterUpdate({ action: 'unsubscribe' }),
    },
    actions: {
      onStatusUpdate: (_, { data: { success, status } }) => {
        if (success) {
          if (status === 'subscribed') {
            enqueueSuccess(t`Přihlášení k odběru novinek bylo úspěšné`)
          } else {
            enqueueSuccess(t`Odhlášení z odběru novinek bylo úspěšné`)
          }
        } else {
          enqueueError(
            t`Něco se nepovedlo. Zkuste to znovu nebo nás kontaktujte.`,
          )
        }
      },
    },
  })

  const isStatusInit = current.matches('status.init')
  const isUpdating = current.matches('update')
  const isSubmitting = isStatusInit || isUpdating

  return (
    <GridItemSmall>
      <Card>
        <CardTitle>
          <Trans>Přihlášení k newsletteru</Trans>
        </CardTitle>
        <CardContent>
          <Trans>
            Pokud chcete, pošleme Vám občasný newsletter s novinkami kolem
            Shopify a souvísejících služeb, které se mohou týkat Vašeho obchodu.
          </Trans>
          <VerticalGap />
          <SubmitButton
            color="default"
            onClick={() => send('TOGGLE')}
            isSubmitting={isSubmitting}
            disabled={isSubmitting}
          >
            {current.matches('status.subscribed') ? (
              <Trans>Odhlásit se z odběru</Trans>
            ) : (
              <Trans>Přihlásit se k odběru</Trans>
            )}
          </SubmitButton>
          <AdminOnly>
            <VerticalGap />
            <Typography variant="caption">
              <Trans>
                Poznámka pro administrátora: Tlačítkem kontroluješ pouze vlastní
                přihlášení k odběru, nikoliv tohoto uživatele
              </Trans>
            </Typography>
          </AdminOnly>
        </CardContent>
      </Card>
    </GridItemSmall>
  )
}
