import React from 'react'

import { collections, isoDate } from 'shopify-payment-core'
import {
  TAccount,
  TAccountActivationStatus,
  TAccountCredentials,
} from 'shopify-payments-types'
import { useImmer } from 'use-immer'

import { useFirebaseFunction } from '../../../hooks/useFirebaseFunction'
import { getFirebase } from '../../../tools/firebase'
import { TActivationResult, TActivationStatus } from '../accountview.types'

export function useAccountActivation<
  TCredentials extends Dictionary & TAccountCredentials
>(account: TAccount) {
  const verifyCredentials = useFirebaseFunction('verifyCredentials')

  const [state, updateState] = useImmer<TActivationResult>({
    status:
      (account.segmentCredentials?.verifiedAt ?? null) === null
        ? 'unknown'
        : 'verified',
  })

  const verifiedCredentials = React.useRef<TCredentials>()

  const handleVerifyCredentials = React.useCallback(
    async (updatedCredentials: TCredentials): Promise<TActivationStatus> => {
      const { success, errorMessage } = await verifyCredentials({
        accountId: account.id,
        credentials: updatedCredentials,
      })
      if (success) {
        verifiedCredentials.current = updatedCredentials
        account.segmentCredentials = updatedCredentials as any
      }
      const nextStatus: TActivationStatus = success ? 'verified' : 'error'
      updateState(draft => {
        draft.status = nextStatus
        draft.errorCode = errorMessage
      })
      return nextStatus
    },
    [account, updateState, verifyCredentials],
  )

  const handleVerifyConfirm = React.useCallback(() => {
    const activationStatus: TAccountActivationStatus = 'verified'

    const accountRef = getFirebase()
      .firestore.collection(collections.accounts)
      .doc(account.id)

    accountRef.update({
      activationStatus,
      segmentCredentials: {
        ...verifiedCredentials.current,
        verifiedAt: isoDate(),
      },
    })
  }, [account])

  const handleCredentialsUpdate = React.useCallback(async () => {
    const accountRef = getFirebase()
      .firestore.collection(collections.accounts)
      .doc(account.id)

    await accountRef.update({
      segmentCredentials: {
        ...verifiedCredentials.current,
        verifiedAt: isoDate(),
      },
    })
  }, [account])

  return {
    state,
    handleVerifyCredentials,
    handleVerifyConfirm,
    handleCredentialsUpdate,
  }
}
