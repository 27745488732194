import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Sentry } from '@speedlo/sentry'
import { SillyErrorBoundary } from '@speedlo/tools/dist/SillyErrorBoundary'
import React from 'react'
import { useImmer } from 'use-immer'

type TProps = Required<SomeChildren>

type TErrorKind = 'none' | 'some'

const DeveloperErrorBoundary = React.lazy(() =>
  import('react-error-guard/lib/DeveloperErrorBoundary'),
)

export const ErrorContainer: React.FC<TProps> = ({ children }) => {
  const [state, updateState] = useImmer({
    kind: 'none' as TErrorKind,
    feedback: false,
    eventId: undefined as ID | undefined,
  })

  const onUnhandledError = React.useCallback(
    (error: Error) => {
      const eventId = Sentry.captureException(error)
      updateState(draft => {
        draft.kind = 'some'
        draft.eventId = eventId
      })
    },
    [updateState],
  )

  const onFeedback = React.useCallback(() => {
    updateState(draft => {
      draft.feedback = true
    })
    Sentry.showReportDialog({ eventId: state.eventId })
  }, [updateState, state])

  const onRestart = React.useCallback(() => {
    window.location.assign('/')
  }, [])

  if (process.env.NODE_ENV === 'production') {
    return (
      <>
        <SillyErrorBoundary onError={onUnhandledError}>
          {state.kind === 'none' && children}
        </SillyErrorBoundary>
        <Dialog
          open={state.kind === 'some' && !state.feedback}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle>
            <Trans>Došlo k neočekávané chybě</Trans>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              V zájmu zlepšení aplikace vyplňte prosím malý formulář, kde zkuste
              vysvětlit jaké kroky předchazeli této chybě. Jakmile chybu
              opravíme, budeme Vás o tom informovat.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onRestart}>
              <Trans>Restartovat aplikaci</Trans>
            </Button>
            <Button onClick={onFeedback} color="secondary">
              <Trans>Vyplnit formulář</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  } else {
    return <DeveloperErrorBoundary>{children}</DeveloperErrorBoundary>
  }
}
