import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@material-ui/core'
import { ChevronRight, ExpandMore } from '@material-ui/icons'
import matchSorter from 'match-sorter'
import React from 'react'
import {
  ColumnInstance,
  Filters,
  Row,
  TableOptions,
  TableState,
  useExpanded,
  useFilters,
  useGroupBy,
  useSortBy,
  useTable,
} from 'react-table'

import { FlexBox } from './FlexBox'

type TProps<T extends object> = TableOptions<T> & {
  onStatePersist(state: TableState<T>): void
  onFilteredRowsChange?(rows: Row<T>[]): void
}

export function StandardTable<T extends object>({
  onStatePersist,
  onFilteredRowsChange,
  ...tableOptions
}: TProps<T>) {
  const filterTypes = React.useMemo(getFilterTypes, [])
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  )

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    flatRows,
  } = useTable(
    { defaultColumn, filterTypes, ...tableOptions },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
  )

  React.useEffect(() => {
    onFilteredRowsChange?.(flatRows)
  })

  React.useEffect(
    () => () => {
      onStatePersist(state)
    },
    [onStatePersist, state],
  )

  const renderRow = React.useCallback((row: Row<T>) => {
    return row.cells.map(cell => {
      let render
      if (cell.isGrouped) {
        render = (
          <FlexBox {...row.getExpandedToggleProps()}>
            {row.isExpanded ? <ExpandMore /> : <ChevronRight />}{' '}
            {cell.render('Cell')}
          </FlexBox>
        )
      } else if (cell.isAggregated) {
        render = cell.render('Aggregated')
      } else if (cell.isRepeatedValue) {
        render = null
      } else {
        render = cell.render('Cell')
      }
      return <TableCell {...cell.getCellProps()}>{render}</TableCell>
    })
  }, [])

  const RenderHeader = React.useCallback(
    ({ column }: SomeChildren & { column: ColumnInstance<T> }) => {
      return (
        <TableCell
          sortDirection={
            column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : false
          }
        >
          <TableSortLabel
            active={column.isSorted}
            direction={column.isSortedDesc ? 'desc' : 'asc'}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {column.render('Header')}
          </TableSortLabel>
          <div>{column.canFilter ? column.render('Filter') : null}</div>
        </TableCell>
      )
    },
    [],
  )

  return (
    <Card>
      <CardContent>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <RenderHeader key={column.id} column={column} />
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>{renderRow(row)}</TableRow>
              )
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

function getFilterTypes(): Filters<any> {
  function fuzzyTextFilterFn(rows: Row[], id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = val => !val

  return { fuzzyText: fuzzyTextFilterFn }
}

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <TextField
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}
