import { Trans } from '@lingui/macro'
import React from 'react'

import { ExternalLink } from '../ExternalLink'
import { FormCheckboxField } from './FormCheckboxField'

type TProps = NoChildren

export const TermsFormCheckbox: React.FC<TProps> = props => {
  return (
    <>
      <FormCheckboxField
        fieldName="terms"
        required
        label={
          <Trans>
            Seznámil jsem se a souhlasím se{' '}
            <ExternalLink href="https://docs.google.com/document/d/e/2PACX-1vSpbedlAIZl77LmHMV2FT-t8dsEgDRiecvdemo5l5oMtMk_GnsnnwGg0USKlWdhe5qPodiqlQLUVEuI/pub">
              všeobecnými obchodními podmínkami
            </ExternalLink>
          </Trans>
        }
      />
    </>
  )
}
