import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import React from 'react'

type TProps = Required<SomeChildren> &
  ButtonProps & {
    isSubmitting: boolean
  }

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export const SubmitButton: React.FC<TProps> = ({
  children,
  isSubmitting,
  ...buttonProps
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          {...buttonProps}
        >
          {children}
        </Button>
        {isSubmitting && (
          <CircularProgress
            size={24}
            color="secondary"
            className={classes.progress}
          />
        )}
      </div>
    </div>
  )
}
