import React from 'react'

import { defaultConfig, segmentLabels } from 'shopify-payment-core'
import { TAccount } from 'shopify-payments-types'

import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Collapse, Typography } from '@material-ui/core'

import { usePageTitle } from '../../../hooks/usePageTitle'
import { SubmitButton } from '../../../molecules/SubmitButton'
import { VerticalGap } from '../../../molecules/VerticalGap'
import { useFreeAccountActivation } from '../hooks/useFreeAccountActivation'
import { AccountViewInstallationConfig } from './AccountViewInstallationConfig'
import { AccountViewInstallationPay } from './AccountViewInstallationPay'
import { AccountViewInstallationSetup } from './AccountViewInstallationSetup'
import { AccountDashboardDeleteAccount } from './dashboard/AccountDashboardDeleteAccount'

type TProps = NoChildren & {
  account: TAccount
}

type TStep = 'setup' | 'config' | 'pay'

export const AccountViewInstallation: React.FC<TProps> = ({ account }) => {
  usePageTitle(i18n._(t`Průvodce instalací brány`), account.id)

  const {
    isFree,
    isSubmitting,
    handleActivateFreeAccount,
  } = useFreeAccountActivation(account)

  const [step, setStep] = React.useState<TStep>(
    account.activationStatus === 'unpaid' && !isFree ? 'pay' : 'setup',
  )

  return (
    <>
      <Collapse in={step === 'setup'}>
        <AccountViewInstallationSetup
          installLink={getInstallLink(account)}
          handleNextStep={() => setStep('config')}
        />
      </Collapse>
      <Collapse in={step === 'config'}>
        <AccountViewInstallationConfig
          account={account}
          settingsLink={getSettingsLink(account)}
          segmentLabel={segmentLabels[account.segmentKey]}
        />
        <VerticalGap />
        <Typography paragraph variant="h5" color="textSecondary">
          <Trans>Důležité upozornění</Trans>
        </Typography>
        <Typography paragraph variant="h6">
          <Trans>
            S ohledem na chystané změny v Shopify API a podmínkách užití, nebude
            možné nainstalovat tuto platební bránu od 1.1.2022. Z toho důvodu
            máte nyní možnost dokončit instalaci zcela ZDARMA. Integrace bude
            funkční do konce března 2022. Pokud v té době bude Shopify souhlasit
            s našimi podmínkami, budete mít možnost začít využívat nové řešení
            bez výpadku.
          </Trans>
        </Typography>
        {isFree ? (
          <SubmitButton
            isSubmitting={isSubmitting}
            color="primary"
            variant="contained"
            onClick={handleActivateFreeAccount}
          >
            <Trans>Údaje mám zadané, aktivovat účet</Trans>
          </SubmitButton>
        ) : (
          <AccountViewInstallationPay account={account} />
        )}
      </Collapse>
      <Collapse in={step === 'pay'}>
        <AccountViewInstallationPay account={account} />
      </Collapse>
      <VerticalGap />
      <VerticalGap />
      <AccountDashboardDeleteAccount account={account} />
    </>
  )
}

function getInstallLink(account: TAccount) {
  return `${getAdminLink(account)}/authorize_gateway/${
    defaultConfig.segments[account.segmentKey].gatewayId
  }`
}

function getSettingsLink(account: TAccount) {
  return `${getAdminLink(account)}/settings/payments/alternative-providers`
}

function getAdminLink(account: TAccount) {
  return `https://${account.shopName}.myshopify.com/admin`
}
