import React from 'react'
import { collections } from 'shopify-payment-core'
import { TAccount, TUser } from 'shopify-payments-types'

import { useFirestoreQuery } from '../../hooks/createFirestore'
import { AdminAccountTable } from './components/AdminAccountTable'

type TProps = NoChildren

export const AdminView: React.FC<TProps> = () => {
  const accounts = useFirestoreQuery<TAccount>('accounts', db =>
    db.collection(collections.accounts),
  )
  const users = useFirestoreQuery<TUser>('users', db =>
    db.collection(collections.users),
  )
  return <AdminAccountTable accounts={accounts} users={users} />
}
