import React from 'react'

import { useAuth } from '../app/AuthProvider'

type TProps = Required<SomeChildren>

export const AdminOnly: React.FC<TProps> = ({ children }) => {
  const authModel = useAuth()
  return authModel.isAdmin ? <>{children}</> : null
}
