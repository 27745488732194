import { Sentry } from '@speedlo/sentry'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { collections } from 'shopify-payment-core'
import { TUser } from 'shopify-payments-types'

import { AuthContext } from '../context/AuthContext'
import { AuthModel, TAuthModel } from '../models/AuthModel'
import { getFirebase } from '../tools/firebase'
import { createSuspense } from '../tools/suspense'

type TProps = Required<SomeChildren>

const authCache = createSuspense()

export const AuthProvider = observer<TProps>(({ children }) => {
  const { firestore, auth } = getFirebase()
  const [authModel] = React.useState(() => AuthModel.create())

  const { read, setFailure, setSuccess } = authCache.getCache<TAuthModel>(
    auth.currentUser?.uid ?? 'guest',
  )

  React.useEffect(() => {
    return auth.onAuthStateChanged(user => {
      if (!user) {
        authModel.clearAuthentication()
        setSuccess(authModel)
        return
      }
      authModel.applyAuthentication(user)
      firestore
        .collection(collections.users)
        .doc(user.uid)
        .get()
        .then(snapshot => {
          if (!snapshot.exists) {
            return
          }
          const userDoc = snapshot.data() as TUser
          authModel.applyUser(userDoc)
          setSuccess(authModel)
        })
        .catch(err => {
          Sentry.captureException(err)
          setFailure(err)
        })
    })
  }, [auth, authModel, firestore, setFailure, setSuccess])

  return <AuthContext.Provider value={read}>{children}</AuthContext.Provider>
})

export function useAuth() {
  return React.useContext(AuthContext)!()
}
