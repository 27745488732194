import React from 'react'

import { Trans } from '@lingui/macro'
import {
  Button,
  CircularProgress,
  Collapse,
  Typography,
} from '@material-ui/core'

import { VerticalGap } from '../../../../molecules/VerticalGap'
import { RM } from '../../../../tools/ramda'
import { TActivationResult, TActivationStatus } from '../../accountview.types'
import { AccountViewActivationCredentials } from '../AccountViewActivationCredentials'
import { AccountViewActivationIntro } from '../AccountViewActivationIntro'
import { TComGateAccount, TComGateCredentialsForm } from './comgate.types'
import { ComGateCredentials } from './ComGateCredentials'

const ComGateCredentialsGuide = React.lazy(() =>
  import('./ComGateCredentialsGuide'),
)

type TProps = NoChildren & {
  account: TComGateAccount
  activationResult: TActivationResult
  handleVerifyCredentials(
    credentials: TComGateCredentialsForm,
  ): Promise<TActivationStatus>
  handleVerifyConfirm(): void
}

export const ComGateActivationLayout: React.FC<TProps> = ({
  account,
  handleVerifyCredentials,
  handleVerifyConfirm,
  activationResult,
}) => {
  const [nextStep, setNextStep] = React.useState(
    activationResult.status !== 'unknown',
  )
  const [showGuide, toggleGuide] = React.useState(false)
  return (
    <>
      <AccountViewActivationIntro account={account} />
      <Collapse in={!nextStep}>
        <Typography paragraph>
          <Trans>
            Před malou chvíli jsme do společnosti ComGate odeslali emailovou
            zprávu obsahující údaje pro nastavení komunikace s tímto účtem. V
            následujících dnech Vás bude ComGate kontaktovat s dalším postupem.
          </Trans>
        </Typography>
        <Typography paragraph>
          <Trans>
            Jakmile bude nastavení ze strany ComGate vyřešeno, vraťte se do této
            aplikace a stiskem tlačítka pokračujte pro dokončení procesu.
          </Trans>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setNextStep(true)}
        >
          <Trans>ComGate nastavení je dokončeno</Trans>
        </Button>
      </Collapse>
      <Collapse in={nextStep}>
        <Typography paragraph>
          <Trans>
            Do následujícího formuláře je nyní potřeba doplnit údaje z
            klientského rozhraní ComGate pro pokračování.
          </Trans>
        </Typography>
        <AccountViewActivationCredentials
          activationResult={activationResult}
          handleVerifyConfirm={handleVerifyConfirm}
        >
          <ComGateCredentials
            key={activationResult.status}
            isVerified={activationResult.status === 'verified'}
            credentials={account.segmentCredentials}
            onSubmit={handleVerifyCredentials}
            extraButtons={
              <Button variant="outlined" onClick={() => toggleGuide(RM.not)}>
                <Trans>Kde najdu tyto údaje?</Trans>
              </Button>
            }
          />
        </AccountViewActivationCredentials>
      </Collapse>
      <Collapse in={showGuide}>
        <VerticalGap />
        <React.Suspense fallback={<CircularProgress />}>
          <ComGateCredentialsGuide />
        </React.Suspense>
      </Collapse>
    </>
  )
}
