import firebase from 'firebase/app'
import { Instance, types } from 'mobx-state-tree'
import { TUser } from 'shopify-payments-types'

export const AuthModel = types
  .model('Auth')
  .props({
    uid: types.maybe(types.string),
  })
  .volatile(() => ({
    user: null as Maybe<TUser>,
  }))
  .views(self => ({
    get name() {
      return self.user?.settings.displayName ?? 'Unknown user'
    },
    get isAdmin() {
      return self.user?.isAdmin ?? false
    },
    get isAuthenticated() {
      return Boolean(self.uid)
    },
  }))
  .actions(self => ({
    applyAuthentication(user: firebase.User) {
      self.uid = user.uid
    },
    applyUser(user: TUser) {
      self.user = user
    },
    clearAuthentication() {
      self.uid = undefined
      self.user = null
    },
  }))

export function createAuthModelWithUser(user: TUser) {
  const model = AuthModel.create()
  model.applyUser(user)
  return model
}

export interface TAuthModel extends Instance<typeof AuthModel> {}
