import React from 'react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { FileCopyOutlined } from '@material-ui/icons'
import * as clipboardy from 'clipboardy'

import { Tooltip } from '@material-ui/core'

import { differenceInDays, formatDistance, parseISO } from 'date-fns'
import { NavLink as RouterLink } from 'react-router-dom'
import { CellProps, Column, FilterProps, TableState, Row } from 'react-table'
import { segmentLabels } from 'shopify-payment-core'
import {
  SegmentKey,
  TAccount,
  TAccountActivationStatus,
  TUser,
} from 'shopify-payments-types'
import { TAccountPaddle } from 'shopify-payments-types/lib/TAccountPaddle'

import {
  Box,
  Button,
  Divider,
  Link,
  MenuItem,
  Select,
  Slider,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'

import { ROUTE_ACCOUNT } from '../../../app/routes'
import { AccountStatusLabel } from '../../../molecules/AccountStatusLabel'
import { ExternalLink } from '../../../molecules/ExternalLink'
import { FlexBox } from '../../../molecules/FlexBox'
import { StandardTable } from '../../../molecules/StandardTable'
import { formatDate } from '../../../tools/formats'
import { RM } from '../../../tools/ramda'
import { TAdminViewDeck } from '../adminview.types'
import { Trans } from '@lingui/macro'
import { useSnacks } from '../../../hooks/useSnacks'

type TProps = NoChildren & TDataProps

type TDataProps = {
  accounts: RoA<TAccount>
  users: RoA<TUser>
}

let tableState: Partial<TableState<TAdminViewDeck>> = {
  groupBy: ['activationStatus'],
}

export const AdminAccountTable: React.FC<TProps> = props => {
  const columns = React.useMemo(getColumns, [])
  const data = React.useMemo(() => getData(props), [props])
  const filteredRows = React.useRef<Row<TAdminViewDeck>[]>([])
  const { enqueueSuccess } = useSnacks()

  const copyEmails = React.useCallback(async () => {
    const list = Array.from(
      filteredRows.current.map(
        r => `http://${r.original.shopName}.myshopify.com;${r.values.email}`,
      ),
    )
    await clipboardy.write(list.join('\n'))
    enqueueSuccess(i18n._(t`Vyfiltrované účty jsou ve schránce`))
  }, [enqueueSuccess])

  return (
    <>
      <Tooltip title={i18n._(t`Zkopíruje vyfiltrované účty do schránky`)}>
        <Button
          onClick={copyEmails}
          title={i18n._(t``)}
          variant="outlined"
          endIcon={<FileCopyOutlined />}
        >
          <Trans>Exportovat účty</Trans>
        </Button>
      </Tooltip>

      <StandardTable
        columns={columns}
        data={data}
        initialState={tableState}
        onStatePersist={state => {
          tableState = state
        }}
        onFilteredRowsChange={rows => {
          filteredRows.current = rows
        }}
      />
    </>
  )
}

function getColumns() {
  const columns: Column<TAdminViewDeck>[] = [
    {
      Header: 'Email',
      accessor: 'owner.email',
      id: 'email',
      aggregate: 'count',
      filter: 'fuzzyText',
      Aggregated: ({ cell: { value: count } }) => `${count} users`,
    },
    {
      Header: 'Shop name',
      accessor: 'shopName',
      aggregate: 'count',
      filter: 'fuzzyText',
      Aggregated: ({ cell: { value: count } }) => `${count} shops`,
      Cell: AccountLinkCell,
    },
    {
      Header: 'Status',
      accessor: 'activationStatus',
      filter: 'equals',
      Filter: props => (
        <SelectColumnFilter<TAccountActivationStatus>
          {...props}
          formatLabel={status => <AccountStatusLabel status={status} />}
        />
      ),
      aggregate: values => {
        const counts = {}
        values.forEach(status => {
          if (!counts[status]) {
            counts[status] = 1
          } else {
            counts[status] += 1
          }
        })
        return counts
      },
      Aggregated: ({ cell: { value } }) => (
        <>
          {Object.keys(value).map(status => (
            <Box mr={0.5} fontSize="0.7rem">
              <AccountStatusLabel status={status as TAccountActivationStatus} />
              {` ${value[status]}x`}
            </Box>
          ))}
        </>
      ),
      Cell: ({ cell: { value } }) => <AccountStatusLabel status={value} />,
    },
    {
      Header: 'Gateway',
      accessor: 'segmentKey',
      width: 100,
      filter: 'equals',
      Filter: props => (
        <SelectColumnFilter<SegmentKey>
          {...props}
          formatLabel={key => <>{segmentLabels[key]}</>}
        />
      ),
      aggregate: values => {
        const labels = Array.from(new Set(values as SegmentKey[])).map(
          key => segmentLabels[key],
        )
        labels.sort()
        return labels
      },
      Aggregated: ({ cell: { value } }) => value.join(', '),
      Cell: ({ cell: { value } }) => {
        return segmentLabels[value] ?? null
      },
    },
    {
      Header: 'Payment count',
      id: 'paymentCount',
      accessor: 'summary.paymentCount',
      aggregate: function minMax(values) {
        const { min, max } = calculateRange(values)
        if (min === 0 && min === max) {
          return '0'
        }
        return `${min}..${max}`
      },
      filter: 'between',
      Filter: NumberRangeColumnFilter,
    },
    {
      Header: 'Last payment days ago',
      id: 'lastPayment',
      accessor: ({ summary: { lastPaymentAt } }) =>
        lastPaymentAt
          ? differenceInDays(new Date(), parseISO(lastPaymentAt))
          : -1,
      aggregate: function minMax(values) {
        const { min, max } = calculateRange(values)
        if (min === -1) {
          return `nikdy..${max}`
        }
        if (min === 0 && min === max) {
          return 'dnes'
        }
        return `${min}..${max}`
      },
      filter: 'between',
      Filter: NumberRangeColumnFilter,
      Cell: ({ cell: { value } }) => {
        return value === -1 ? 'nikdy' : value
      },
    },
    {
      Header: 'To be removed',
      id: 'delete',
      accessor: ({ willBeRemovedAt }) =>
        willBeRemovedAt
          ? formatDistance(parseISO(willBeRemovedAt), new Date())
          : null,
      aggregate: function minMax(values) {
        const count = values.filter(value => value !== null).length
        return `${count} to remove`
      },
      Cell: ({ cell: { value } }) => (value === null ? 'never' : value),
    },
    {
      Header: 'Invoice',
      accessor: 'paddle',
      aggregate: function sumPaid(values) {
        return values.filter(Boolean).length
      },
      Aggregated: ({ cell: { value } }) => `${value} paid`,
      Cell: ({ cell: { value } }) => {
        if (!value) {
          return ''
        }
        const { invoiceUrl, createdAt } = value as TAccountPaddle
        return (
          <ExternalLink noIcon href={invoiceUrl}>
            {formatDate(parseISO(createdAt))}
          </ExternalLink>
        )
      },
    },
  ]
  return columns
}

function getData({ accounts, users }: TDataProps): TAdminViewDeck[] {
  return accounts.map(account => ({
    ...account,
    owner: users.find(RM.propEq('id', account.ownerId))!,
  }))
}

function AccountLinkCell({ row, cell: { value } }: CellProps<TAdminViewDeck>) {
  return (
    <FlexBox alignItems="flex-items" justifyContent="space-between">
      <div>
        <Search fontSize="small" />
        <Link
          component={RouterLink}
          to={`${ROUTE_ACCOUNT}/${row.original.id}`}
          color="textPrimary"
        >
          {value}
        </Link>
      </div>
      <div>
        <Box ml={2} />
        <ExternalLink
          href={`https://${row.original.shopName}.myshopify.com`}
          color="textPrimary"
        >
          {' '}
        </ExternalLink>
      </div>
    </FlexBox>
  )
}

function NumberRangeColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, id },
}: FilterProps<TAdminViewDeck>) {
  const handleChange = (event: any, newValue: number | number[]) => {
    setFilter(newValue as number[])
  }

  const { min, max } = React.useMemo(
    () => calculateRange(preFilteredRows.map(row => row.values[id])),
    [id, preFilteredRows],
  )

  return (
    <Slider
      min={min}
      max={max}
      step={10}
      value={filterValue || [min, max]}
      onChange={handleChange}
      valueLabelDisplay="auto"
    />
  )
}

function calculateRange(values: number[]) {
  let min = values.length ? values[0] : 0
  let max = values.length ? values[0] : 0
  values.forEach(value => {
    min = Math.min(value, min)
    max = Math.max(value, max)
  })
  return { min, max }
}

function SelectColumnFilter<T>({
  column: { filterValue, preFilteredRows, setFilter, id },
  formatLabel,
}: FilterProps<TAdminViewDeck> & { formatLabel?: (value: T) => ReactNode }) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setFilter(event.target.value || undefined)
    },
    [setFilter],
  )

  return (
    <Select onChange={handleChange} value={filterValue} fullWidth>
      <MenuItem value="">all</MenuItem>
      <Divider />
      {options.map((option: any) => (
        <MenuItem value={option} key={option}>
          {formatLabel?.(option) ?? option}
        </MenuItem>
      ))}
    </Select>
  )
}
