import React from 'react'

import { GoPay } from 'shopify-payments-types'

import { Trans } from '@lingui/macro'
import { Button, Typography } from '@material-ui/core'

import { TActivationResult, TActivationStatus } from '../../accountview.types'
import { AccountViewActivationCredentials } from '../AccountViewActivationCredentials'
import { AccountViewActivationIntro } from '../AccountViewActivationIntro'
import { TGoPayCredentialsForm } from './gopay.types'
import { GoPayActivationRegister } from './GoPayActivationRegister'
import { GoPayCredentials } from './GoPayCredentials'

type TProps = NoChildren & {
  account: GoPay.TSegmentAccount
  activationResult: TActivationResult
  handleVerifyCredentials(
    credentials: TGoPayCredentialsForm,
  ): Promise<TActivationStatus>
  handleVerifyConfirm(): void
}

export const GoPayActivationLayout: React.FC<TProps> = ({
  account,
  handleVerifyCredentials,
  handleVerifyConfirm,
  activationResult,
}) => {
  const [showHint, setShowHint] = React.useState(false)
  return (
    <>
      <AccountViewActivationIntro account={account} />
      <Typography>
        <Trans>
          Máte již GoPay účet zřízený? Potřebujeme od Vás údaje pro komunikaci s
          platební bránou. Tyto jste obdrželi od společnosti GoPay pravděpodobně
          formou emailové zpravy.
        </Trans>
      </Typography>
      <AccountViewActivationCredentials
        activationResult={activationResult}
        handleVerifyConfirm={handleVerifyConfirm}
      >
        <>
          <GoPayCredentials
            key={activationResult.status}
            isVerified={activationResult.status === 'verified'}
            credentials={account.segmentCredentials}
            onSubmit={handleVerifyCredentials}
            extraButtons={
              <Button variant="outlined" onClick={() => setShowHint(s => !s)}>
                <Trans>GoPay účet ještě nemáte?</Trans>
              </Button>
            }
          />
          {activationResult.errorCode?.startsWith('202') && (
            <Typography>
              <Trans>
                Pokud jste si jisti zadanými údaji, je pravděpodobné, že se
                jedná o testovací údaje. GoPay tyto standardně uděluje pokud
                jste se registrovali jinak než přes náš formulař. Prosím{' '}
                <b>kontaktujte GoPay</b> a požádejte je o produkční údaje s
                informací, že využíváte integraci od společnosti{' '}
                <b>Sounds Good Agency s.r.o.</b>
              </Trans>
            </Typography>
          )}
        </>
      </AccountViewActivationCredentials>
      {account.segmentCredentials.verifiedAt === null && (
        <GoPayActivationRegister show={showHint} />
      )}
    </>
  )
}
