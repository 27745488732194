import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Card, CardContent, Grid } from '@material-ui/core'
import { XForm } from '@speedlo/xform'
import React from 'react'
import {
  collections,
  prefixDataFields,
  segmentLabels,
} from 'shopify-payment-core'
import { SegmentKey, TAccount } from 'shopify-payments-types'

import { useFirestore } from '../../../../hooks/createFirestore'
import { useSnacks } from '../../../../hooks/useSnacks'
import { CardTitle } from '../../../../molecules/CardTitle'
import { FormSubmitButton } from '../../../../molecules/forms/FormSubmitButton'
import { VerticalGap } from '../../../../molecules/VerticalGap'
import { ComGateConfig } from '../comgate/ComGateConfig'
import { ConfirmoConfig } from '../confirmo/ConfirmoConfig'
import { GoPayConfig } from '../gopay/GoPayConfig'

type TProps = NoChildren & {
  account: TAccount
}

const configBySegment: Record<SegmentKey, React.FC> = {
  gopay: GoPayConfig,
  comgate: ComGateConfig,
  confirmo: ConfirmoConfig,
}

export const AccountDashboardSegmentConfig: React.FC<TProps> = ({
  account,
}) => {
  const { enqueueSuccess } = useSnacks()
  const { firestore } = useFirestore()
  const onSubmit = React.useCallback(
    async values => {
      await firestore
        .doc(`${collections.accounts}/${account.id}`)
        .update(prefixDataFields(values, 'segmentConfig.'))
      enqueueSuccess(i18n._(t`Nastavení uloženo`))
    },
    [account, enqueueSuccess, firestore],
  )

  const segmentLabel = segmentLabels[account.segmentKey]

  return (
    <Grid item lg={6} sm={6} xl={6} xs={12}>
      <Card>
        <CardTitle>
          <Trans>Nastavení {segmentLabel}</Trans>
        </CardTitle>
        <CardContent>
          <XForm initialValues={account.segmentConfig} onSubmit={onSubmit}>
            {React.createElement(configBySegment[account.segmentKey])}
            <VerticalGap />
            <FormSubmitButton>
              <Trans>Uložit nastavení</Trans>
            </FormSubmitButton>
          </XForm>
        </CardContent>
      </Card>
    </Grid>
  )
}
