import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import React from 'react'

import { FormDropdownField } from '../../../../../molecules/forms/FormDropdownField'

type TLanguage = {
  value: string
  label: string | ReactNode
}

type TProps = NoChildren & {
  languages: RoA<TLanguage>
  fieldName?: string
}

export const AccountDashboardLanguagePicker: React.FC<TProps> = ({
  languages,
  fieldName = 'language',
}) => {
  return (
    <FormDropdownField<TLanguage>
      fieldName={fieldName}
      options={languages}
      emptyLabel={
        <Trans comment="Popisek pro výchozí nastavení výběru jazyka">
          <i>Automaticky</i>
        </Trans>
      }
      label={i18n._(t`Výchozí jazyk brány pro zákazníka`)}
    />
  )
}
