import { makeStyles } from '@material-ui/core'
import { getYear } from 'date-fns'
import React from 'react'

import { ExternalLink } from './ExternalLink'

type TProps = NoChildren

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    bottom: 0,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
  },
}))

export const Footer: React.FC<TProps> = props => {
  const classes = useStyles()
  const year = getYear(new Date()).toString()
  return (
    <div className={classes.root}>
      <ExternalLink
        color="inherit"
        href="http://status.platebni-brany.cz"
        noIcon
      >
        Stav služby
      </ExternalLink>{' '}
      <ExternalLink color="inherit" href="https://soundsgood.agency/" noIcon>
        Sounds Good Agency s.r.o &copy; 2019 - {year}
      </ExternalLink>{' '}
      <ExternalLink
        color="inherit"
        href="https://podnikat.online/pages/vseobecne-obchodni-podminky-platebni-brany-na-shopify"
        noIcon
      >
        Obchodní podmínky
      </ExternalLink>
    </div>
  )
}
