import { Trans } from '@lingui/macro'
import { Box, Typography } from '@material-ui/core'
import React from 'react'

import { TActivationResult } from '../accountview.types'
import { AccountViewActivationStatus } from './AccountViewActivationStatus'

type TProps = Required<SomeChildren> & {
  activationResult: TActivationResult
  handleVerifyConfirm(): void
  errorMessage?: ReactNode
}

export const AccountViewActivationCredentials: React.FC<TProps> = ({
  children,
  activationResult,
  handleVerifyConfirm,
  errorMessage = <Trans>Ověření zadaných údajů selhalo.</Trans>,
}) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" maxWidth="50rem">
        <Box mt={1} width="100%" alignSelf="center">
          {children}
          {activationResult.errorCode && (
            <Box my={1}>
              <Typography color="error">{errorMessage}</Typography>
              <Typography color="textSecondary" variant="caption">
                ({activationResult.errorCode?.toUpperCase()})
              </Typography>
            </Box>
          )}
        </Box>
        <AccountViewActivationStatus
          status={activationResult.status}
          handleContinue={handleVerifyConfirm}
        />
      </Box>
    </>
  )
}
