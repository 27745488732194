import { SnackbarProvider } from 'notistack'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from './AuthProvider'
import { Intercom } from './Intercom'
import { Routing } from './Routing'

export const App: React.FC<NoChildren> = () => (
  <AuthProvider>
    <BrowserRouter>
      <Intercom />
      <SnackbarProvider maxSnack={3}>
        <Routing />
      </SnackbarProvider>
    </BrowserRouter>
  </AuthProvider>
)
