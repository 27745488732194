import { Plural, Trans } from '@lingui/macro'
import { Divider, Link, MenuItem, Select } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { format, isValid, parseISO } from 'date-fns'
import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { CellProps, Column, FilterProps, TableState } from 'react-table'
import { segmentLabels } from 'shopify-payment-core'
import { SegmentKey, TRegistration } from 'shopify-payments-types'

import { ROUTE_ACCOUNT } from '../../../app/routes'
import { FlexBox } from '../../../molecules/FlexBox'
import { StandardTable } from '../../../molecules/StandardTable'
import { TAdminReferralsViewDeck } from '../adminrefview.types'

type TProps = NoChildren & TDataProps

type TDataProps = {
  registrations: RoA<TRegistration>
}

type TReferral = TAdminReferralsViewDeck

let tableState: Partial<TableState<TReferral>> = {
  groupBy: ['referrer'],
  sortBy: [{ id: 'createdAt', desc: true }],
}

export const AdminReferralsTable = (props: TProps) => {
  const columns = React.useMemo(getColumns, [])
  const data = React.useMemo(() => getData(props), [props])

  return (
    <StandardTable
      columns={columns}
      data={data}
      initialState={tableState}
      onStatePersist={state => {
        tableState = state
      }}
    />
  )
}

function getColumns() {
  const columns: Column<TReferral>[] = [
    {
      Header: 'Referrer',
      accessor: 'referrer',
      filter: 'equals',
    },
    {
      Header: 'Email',
      accessor: 'email',
      aggregate: 'count',
      filter: 'fuzzyText',
      Aggregated: ({ cell: { value: count } }) => (
        <Plural
          value={count}
          one="# registrace"
          few="# registrace"
          other="# registrací"
        />
      ),
    },
    {
      Header: 'Obchod',
      accessor: 'shopName',
      aggregate: 'count',
      filter: 'fuzzyText',
      Aggregated: ({ cell: { value: count } }) => (
        <Plural
          value={count}
          one="# obchod"
          few="# obchody"
          other="# obchodů"
        />
      ),
      Cell: AccountLinkCell,
    },
    {
      Header: 'Brána',
      accessor: 'segmentKey',
      width: 100,
      filter: 'equals',
      Filter: props => (
        <SelectColumnFilter<SegmentKey>
          {...props}
          formatLabel={key => <>{segmentLabels[key]}</>}
        />
      ),
      aggregate: values => {
        const labels = Array.from(new Set(values as SegmentKey[])).map(
          key => segmentLabels[key],
        )
        labels.sort()
        return labels
      },
      Aggregated: ({ cell: { value } }) => value.join(', '),
      Cell: ({ cell: { value } }) => {
        return segmentLabels[value] ?? null
      },
    },
    {
      Header: 'Datum registrace',
      accessor: 'createdAt',
      Cell: ({ cell: { value } }) => {
        return isValid(value) && format(value, 'PPP')
      },
      sortType: 'datetime',
      aggregate: 'count',
      Aggregated: ({ cell: { value: count } }) => (
        <Plural
          value={count}
          one="# registrace"
          few="# registrace"
          other="# registrací"
        />
      ),
    },
  ]
  return columns
}

function getData({ registrations }: TDataProps): TReferral[] {
  return registrations.map(reg => ({
    accountId: reg.id,
    referrer: reg.meta!.utm_source,
    email: reg.email,
    shopName: reg.shopName,
    segmentKey: reg.segmentKey,
    createdAt: parseISO(reg.createdAt),
  }))
}

function AccountLinkCell({ row, cell: { value } }: CellProps<TReferral>) {
  return (
    <FlexBox alignItems="stretch">
      <Search fontSize="small" />
      <Link
        component={RouterLink}
        to={`${ROUTE_ACCOUNT}/${row.original.accountId}`}
        color="textPrimary"
      >
        {value}
      </Link>
    </FlexBox>
  )
}

function SelectColumnFilter<T>({
  column: { filterValue, preFilteredRows, setFilter, id },
  formatLabel,
}: FilterProps<TReferral> & { formatLabel?: (value: T) => ReactNode }) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setFilter(event.target.value || undefined)
    },
    [setFilter],
  )

  return (
    <Select onChange={handleChange} value={filterValue} fullWidth>
      <MenuItem value="">
        <Trans>vše</Trans>
      </MenuItem>
      <Divider />
      {options.map((option: any) => (
        <MenuItem value={option} key={option}>
          {formatLabel?.(option) ?? option}
        </MenuItem>
      ))}
    </Select>
  )
}
