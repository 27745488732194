import { Trans } from '@lingui/macro'
import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { segmentLabels } from 'shopify-payment-core'
import { SegmentKey } from 'shopify-payments-types'

import { FormSubmitButton } from '../../../molecules/forms/FormSubmitButton'
import { TermsFormCheckbox } from '../../../molecules/forms/TermsFormCheckbox'
import { ShopNameFormInput } from '../../../molecules/ShopNameFormInput'
import { VerticalGap } from '../../../molecules/VerticalGap'

type TProps = NoChildren & {
  segmentKey: SegmentKey
}

export const AddAccountViewFormLayout: React.FC<TProps> = ({ segmentKey }) => {
  return (
    <Box maxWidth="40rem">
      <Typography gutterBottom>
        <Trans>
          Pomocí následující formuláře můžete přidat další obchod, pro který
          chcete využít naše {segmentLabels[segmentKey]} řešení.
        </Trans>
      </Typography>
      <VerticalGap />
      <ShopNameFormInput />
      <VerticalGap />
      <TermsFormCheckbox />
      <VerticalGap />
      <FormSubmitButton>
        <Trans>Přidat obchod</Trans>
      </FormSubmitButton>
    </Box>
  )
}
