import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import React from 'react'

import { ExternalLink } from '../../../../molecules/ExternalLink'

type TProps = NoChildren

export const GoPayConfigPayPal: React.FC<TProps> = props => {
  return (
    <>
      <Typography paragraph>
        <Trans>
          Pro aktivaci PayPal následujte{' '}
          <ExternalLink href="https://help.gopay.com/cs/tema/gopay-ucet/gopay-obchodni-ucet/administrace-a-navody-obchodni-ucet/postup-aktivace-platebni-metody-paypal-z-obchodniho-uctu-gopay">
            tento návod
          </ExternalLink>
        </Trans>
      </Typography>
    </>
  )
}
