import React from 'react'

import { TAccount } from 'shopify-payments-types'

import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { Grid } from '@material-ui/core'

import { usePageTitle } from '../../../hooks/usePageTitle'
import { AdminOnly } from '../../../molecules/AdminOnly'
import { ComGateConnectionSetup } from './comgate/ComGateConnectionSetup'
import { AccountDashboardAdminNote } from './dashboard/AccountDashboardAdminNote'
import { AccountDashboardDeleteAccount } from './dashboard/AccountDashboardDeleteAccount'
import { AccountDashboardNewsletter } from './dashboard/AccountDashboardNewsletter'
import { AccountDashboardOwnerAuthEmail } from './dashboard/AccountDashboardOwnerAuthEmail'
import { AccountDashboardPaddle } from './dashboard/AccountDashboardPaddle'
import { AccountDashboardSegmentConfig } from './dashboard/AccountDashboardSegmentConfig'
import { AccountDashboardSegmentCredentials } from './dashboard/AccountDashboardSegmentCredentials'
import { AccountDashboardShopifyCredentials } from './dashboard/AccountDashboardShopifyCredentials'

type TProps = NoChildren & {
  account: TAccount
}

export const AccountViewDashboard: React.FC<TProps> = ({ account }) => {
  usePageTitle(i18n._(t`Profil obchodu`), account.id)

  return (
    <Grid container spacing={4}>
      <AccountDashboardSegmentConfig account={account} />
      <AdminOnly>
        <AccountDashboardOwnerAuthEmail account={account} />
        <AccountDashboardAdminNote account={account} />
      </AdminOnly>
      <AccountDashboardNewsletter />
      <AccountDashboardPaddle account={account} />
      <AccountDashboardShopifyCredentials account={account} />
      <AccountDashboardSegmentCredentials account={account} />
      <AccountDashboardDeleteAccount account={account} />
      {account.segmentKey === 'comgate' && (
        <ComGateConnectionSetup account={account} />
      )}
    </Grid>
  )
}
