import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import React from 'react'

import { usePageTitle } from '../../../hooks/usePageTitle'
import { LoginForm } from '../../../molecules/LoginForm'
import { TLoginStatus } from '../loginview.types'
import { LoginViewStatus } from './LoginViewStatus'

type TProps = NoChildren & {
  status: Maybe<TLoginStatus>
  onSubmit(email: string): Promise<void>
}

export const LoginViewForm: React.FC<TProps> = ({ status, onSubmit }) => {
  usePageTitle(i18n._(t`Přihlášení do aplikace`))
  return (
    <>
      <Typography gutterBottom>
        <Trans>
          Pošleme Vám emailovou zprávu s odkazem kterým se přihlásíte do
          aplikace.
        </Trans>
      </Typography>
      <LoginForm
        onSubmit={onSubmit}
        buttonText={<Trans>Poslat email pro přihlášení</Trans>}
      />
      {status && <LoginViewStatus status={status} />}
    </>
  )
}
