import React from 'react'

import { Redirect, useLocation } from 'react-router-dom'

import { useAuth } from '../../app/AuthProvider'
import { ROUTE_ROOT } from '../../app/routes'
import { useAuthEmail } from '../../hooks/useAuthEmail'
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction'
import { useSnacks } from '../../hooks/useSnacks'
import { getFirebase } from '../../tools/firebase'
import { LoginViewForm } from './components/LoginViewForm'
import { TLoginStatus } from './loginview.types'

type TProps = NoChildren

export const LoginView: React.FC<TProps> = () => {
  const { auth } = getFirebase()
  const location = useLocation()
  const authModel = useAuth()
  const [status, setStatus] = React.useState<Maybe<TLoginStatus>>(null)
  const { enqueueError } = useSnacks()

  const signIn = useFirebaseFunction('signIn')

  const origin = (location.state && location.state.origin) || ROUTE_ROOT

  const [, setAuthEmail] = useAuthEmail()

  const onSubmit = React.useCallback(
    async (email: string) => {
      try {
        if (process.env.NODE_ENV === 'development') {
          if (email.indexOf('||') > 0) {
            const [mail, password] = email.split('||')
            await auth.signInWithEmailAndPassword(mail, password)
            return
          }
          setStatus('sent')
        } else {
          const { status } = await signIn({ email, origin })
          setStatus(status)
        }
        setAuthEmail(email)
      } catch (err) {
        enqueueError(`${err.message} (${err.code})`)
        setStatus('error')
      }
    },
    [auth, enqueueError, origin, setAuthEmail, signIn],
  )

  if (authModel.isAuthenticated) {
    return <Redirect to={origin} />
  }

  return <LoginViewForm status={status} onSubmit={onSubmit} />
}
