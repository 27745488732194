import { List } from '@material-ui/core'
import { Explore, SupervisorAccount } from '@material-ui/icons'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { collections, defaultConfig } from 'shopify-payment-core'
import { SegmentKey, TAccount } from 'shopify-payments-types'

import { useAuth } from '../../app/AuthProvider'
import {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_ADD,
  ROUTE_ADMIN,
  ROUTE_ADMIN_REFERRALS,
} from '../../app/routes'
import { useFirestoreQuery } from '../../hooks/createFirestore'
import { RM } from '../../tools/ramda'
import { SidebarViewItem } from './components/SidebarViewItem'
import { SidebarViewSegment } from './components/SidebarViewSegment'

type TProps = NoChildren & {
  className?: string
}

export const SidebarView: React.FC<TProps> = ({ className }) => {
  const history = useHistory()
  const match = useRouteMatch<{ accountId: ID }>(`${ROUTE_ACCOUNT}/:accountId`)
  const authModel = useAuth()
  const accounts = useFirestoreQuery<TAccount>(`sidebar-${authModel.uid}`, db =>
    db.collection(collections.accounts).where('ownerId', '==', authModel.uid),
  )
  const bySegment = RM.groupBy(({ segmentKey }) => segmentKey, accounts)
  return (
    <div className={className}>
      <List>
        <SidebarViewItem to={ROUTE_ADMIN} icon={SupervisorAccount} adminOnly>
          Shop accounts [A]
        </SidebarViewItem>
        <SidebarViewItem to={ROUTE_ADMIN_REFERRALS} icon={Explore} adminOnly>
          Referrals [A]
        </SidebarViewItem>
      </List>
      {Object.keys(defaultConfig.segments).map(key => (
        <SidebarViewSegment
          key={key}
          segmentKey={key as SegmentKey}
          accounts={bySegment[key] || []}
          activeAccount={match && match.params.accountId}
          onAdd={() => history.push(`${ROUTE_ACCOUNT_ADD}/${key}`)}
        />
      ))}
    </div>
  )
}
