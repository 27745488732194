import {
  AppBar,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
} from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { ROUTE_LOGOUT } from '../../../app/routes'
import { TTheme } from '../../../app/ThemeProvider'
import { PageTitle } from '../../../molecules/PageTitle'
import { TopBarLogo } from '../../../molecules/TopBarLogo'
import { UserName } from './UserName'

type TProps = NoChildren & {
  onSidebarOpen(): void
}

const useStyles = makeStyles((theme: TTheme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}))

export const MainTopBar: React.FC<TProps> = ({ onSidebarOpen }) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <AppBar className={classes.root}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <TopBarLogo />
        <PageTitle />
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <UserName />
        </Hidden>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={() => history.push(ROUTE_LOGOUT)}
        >
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
