import { Machine } from 'xstate'

export const newsletterMachine = Machine({
  id: 'newsletter',
  initial: 'status',
  states: {
    status: {
      initial: 'init',
      states: {
        init: invokeStatusInit(),
        subscribed: asToggle('unsubscribe'),
        unsubscribed: asToggle('subscribe'),
      },
    },
    update: {
      states: {
        subscribe: invokeUpdate('subscribe'),
        unsubscribe: invokeUpdate('unsubscribe'),
      },
    },
    failed: {},
  },
})

type TStatus = 'subscribed' | 'unsubscribed'
type TAction = 'subscribe' | 'unsubscribe'

function onStatus(status: TStatus) {
  return {
    cond: (_, ev) => ev.data.status === status,
    target: `#newsletter.status.${status}`,
  }
}

function onStatusUpdate(status: TStatus) {
  return {
    cond: (_, ev) => ev.data.status === status,
    actions: 'onStatusUpdate',
    target: `#newsletter.status.${status}`,
  }
}

function asToggle(action: TAction) {
  return {
    on: {
      TOGGLE: `#newsletter.update.${action}`,
    },
  }
}

function invokeStatusInit() {
  return {
    invoke: {
      src: 'getStatus',
      onDone: [onStatus('subscribed'), onStatus('unsubscribed')],
      onError: '#newsletter.failed',
    },
  }
}

function invokeUpdate(action: TAction) {
  return {
    invoke: {
      src: action,
      onDone: [onStatusUpdate('subscribed'), onStatusUpdate('unsubscribed')],
      onError: '#newsletter.failed',
    },
  }
}
