import { makeStyles, Paper } from '@material-ui/core'
import React from 'react'

type TProps = Required<SomeChildren>

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
  paper: {
    padding: theme.spacing(2),
    width: '40rem',
  },
}))

export const GuestContent: React.FC<TProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>{children}</Paper>
    </div>
  )
}
