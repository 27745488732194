import { Trans } from '@lingui/macro'
import { Box, Button, FormHelperText, Typography } from '@material-ui/core'
import { Launch } from '@material-ui/icons'
import React from 'react'

type TProps = NoChildren & {
  installLink: string
  handleNextStep(): void
}

export const AccountViewInstallationSetup: React.FC<TProps> = ({
  installLink,
  handleNextStep,
}) => {
  return (
    <>
      <Typography paragraph variant="body2">
        <Trans>
          Dalším krokem je instalace samotné integrace do Vašeho Shopify
          obchodu.
        </Trans>
      </Typography>
      <Typography paragraph>
        <Trans>
          Jakmile instalaci potvrdíte, <b>vraťte se do této aplikace</b>.
        </Trans>
      </Typography>
      <Box display="flex" alignItems="flex-start">
        <Box display="flex" flexDirection="column" mr={1}>
          <Button
            color="primary"
            variant="contained"
            component="a"
            href={installLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleNextStep}
          >
            <Trans>Instalace brány do Shopify</Trans>
            <Launch />
          </Button>
          <FormHelperText>
            <Trans>Bude vyžadováno přihlášení do Vašeho obchodu.</Trans>
          </FormHelperText>
        </Box>
        <Button color="secondary" variant="outlined" onClick={handleNextStep}>
          <Trans>Tento krok jsem již udělal/a</Trans>
        </Button>
      </Box>
    </>
  )
}
