import React from 'react'

import { ComGate } from 'shopify-payments-types'

import { useAccountActivation } from '../../hooks/useAccountActivation'
import { TComGateAccount } from './comgate.types'
import { ComGateActivationLayout } from './ComGateActivationLayout'

type TProps = NoChildren & {
  account: TComGateAccount
}

export const ComGateActivationView: React.FC<TProps> = ({ account }) => {
  const {
    state,
    handleVerifyConfirm,
    handleVerifyCredentials,
  } = useAccountActivation<ComGate.TSegmentCredentials>(account)

  return (
    <ComGateActivationLayout
      activationResult={state}
      handleVerifyCredentials={handleVerifyCredentials}
      handleVerifyConfirm={handleVerifyConfirm}
      account={account}
    />
  )
}
