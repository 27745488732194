type TCacheStatus = 'pending' | 'success' | 'failure'

type TCache<T> = TCacheEntryApi<T> & {
  read(): T
}

type TCacheEntry<T> = {
  key: string
  status: TCacheStatus
  result: T | undefined
  error: Error | undefined
}

type TCacheEntryApi<T> = {
  readonly setSuccess: (result: T, onUpdate?: () => void) => void
  readonly setFailure: (error: Error, onUpdate?: () => void) => void
  readonly onCreate: (cb: () => void) => void
}

function createCacheEntry<T>(key: string) {
  const state: TCacheEntry<T> = {
    key,
    status: 'pending',
    result: undefined,
    error: undefined,
  }

  const { suspender, resolve, reject } = createSuspender()

  const setSuccess: TCacheEntryApi<T>['setSuccess'] = (result, onUpdate) => {
    const prevStatus = state.status
    state.status = 'success'
    state.result = result
    if (prevStatus === 'pending') {
      resolve()
    } else if (onUpdate) {
      onUpdate()
    }
  }

  const setFailure: TCacheEntryApi<T>['setFailure'] = (error, onUpdate) => {
    const prevStatus = state.status
    state.error = error
    state.status = 'failure'
    if (prevStatus === 'pending') {
      reject(error)
    } else if (onUpdate) {
      onUpdate()
    }
  }

  // const updateResult: TCacheEntryApi<T>['updateResult'] = result => {
  //   state.result = result
  // }

  return {
    state: state as Readonly<TCacheEntry<T>>,
    suspender,
    setSuccess,
    setFailure,
    // updateResult,
  }
}

function createSuspender() {
  let resolve: () => void
  let reject: (err: Error) => void
  const suspender = new Promise((resolve_, reject_) => {
    resolve = resolve_
    reject = reject_
  })
  // @ts-ignore
  return { suspender, resolve, reject }
}

export function createSuspense() {
  const cache = {}

  function createCache<T>(key: string): TCache<T> {
    const { state, setSuccess, setFailure, suspender } = createCacheEntry<T>(
      key,
    )

    let onCreateCalled = false

    return {
      read() {
        if (state.status === 'pending') {
          throw suspender
        } else if (state.status === 'failure') {
          throw state.error
        }
        return state.result!
      },
      setSuccess,
      setFailure,
      onCreate(cb) {
        if (!onCreateCalled) {
          cb()
          onCreateCalled = true
        }
      },
    }
  }

  function getCache<T>(key: string): TCache<T> {
    if (!cache[key]) {
      const created = createCache<T>(key)
      cache[key] = created
      return created
    }
    return cache[key]
  }

  return { getCache }
}

export function createSimpleSuspense<TValue = unknown>(
  promise: Promise<TValue>,
) {
  let status: TCacheStatus = 'pending'
  let resolvedValue: TValue

  promise
    .then(value => {
      resolvedValue = value
      status = 'success'
    })
    .catch(() => (status = 'failure'))

  return function suspend() {
    if (status === 'pending') {
      throw promise
    }
    return resolvedValue
  }
}
