import React from 'react'
import { Route } from 'react-router-dom'

import { GuestLayout } from '../layouts/GuestLayout'
import { TRouteProps } from '../typings/types'

type TProps = NoChildren & TRouteProps

export const GuestRoute: React.FC<TProps> = ({ view: View, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={() => (
        <GuestLayout>
          <View />
        </GuestLayout>
      )}
    />
  )
}
