import { useObserver } from 'mobx-react-lite'
import React from 'react'

import { useAuth } from '../app/AuthProvider'
import { PageLoader } from '../molecules/PageLoader'
import { RedirectToLogin } from '../molecules/RedirectToLogin'
import { getFirebase } from '../tools/firebase'

export const LogoutView: React.FC<NoChildren> = () => {
  const { auth } = getFirebase()
  const authModel = useAuth()

  React.useEffect(() => {
    auth.signOut().then(() => {
      authModel.clearAuthentication()
    })
  }, [auth, authModel])

  return useObserver(() =>
    authModel.isAuthenticated ? (
      <PageLoader />
    ) : (
      <RedirectToLogin useOrigin={false} />
    ),
  )
}
