import React from 'react'

import { Confirmo } from 'shopify-payments-types'

import { useAccountActivation } from '../../hooks/useAccountActivation'
import { TConfirmoAccount } from './confirmo.types'
import { ConfirmoActivationLayout } from './ConfirmoActivationLayout'

type TProps = NoChildren & {
  account: TConfirmoAccount
}

export const ConfirmoActivationView: React.FC<TProps> = ({ account }) => {
  const {
    state,
    handleVerifyConfirm,
    handleVerifyCredentials,
  } = useAccountActivation<Confirmo.TSegmentCredentials>(account)

  return (
    <ConfirmoActivationLayout
      activationResult={state}
      handleVerifyCredentials={handleVerifyCredentials}
      handleVerifyConfirm={handleVerifyConfirm}
      account={account}
    />
  )
}
