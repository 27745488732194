import { i18n, Messages } from '@lingui/core'
import { DETECTORS, LocaleResolver, TRANSFORMERS } from 'locales-detector'
import * as Plurals from 'make-plural'

export async function activateLocale(locale: string) {
  const plurals = (Plurals as any)[locale]
  if (plurals) {
    i18n.loadLocaleData(locale, { plurals })
  }

  return loadLocale(locale).then(messages => {
    i18n.load(locale, messages)
    i18n.activate(locale)
  })
}

export async function loadLocale(locale: string): Promise<Messages> {
  const getMessages = (data: any) => data.messages
  if (process.env.NODE_ENV !== 'production') {
    return import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `@lingui/loader!../locales/${locale}.po`
    ).then(getMessages)
  } else {
    return import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `../locales/${locale}.js`
    ).then(getMessages)
  }
}

export function detectLocale(supportedLocales: string[]) {
  const resolver = new LocaleResolver(
    [new DETECTORS.NavigatorDetector()],
    [
      new TRANSFORMERS.LanguageOnlyTransformer(),
      new TRANSFORMERS.AllowOnlyTransformer(supportedLocales),
    ],
  )
  const [first] = resolver.getLocales()
  return first || supportedLocales[0]
}
