import { Button } from '@material-ui/core'
import React from 'react'

import { appConfig } from '../config'
import { TIntercom } from '../lib/intercom'

type TProps = NoChildren

export const ContactLink: React.FC<TProps> = props => {
  return (
    <Button
      variant="text"
      color="secondary"
      onClick={e => {
        if ('Intercom' in window) {
          e.preventDefault()
          ;(window['Intercom'] as TIntercom)('showNewMessage')
        } else {
          window.location.assign(`mailto:${appConfig.supportEmail}`)
        }
      }}
    >
      kontaktujte nás
    </Button>
  )
}
