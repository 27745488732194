import React from 'react'

import { GoPay } from 'shopify-payments-types'

import { t, Trans } from '@lingui/macro'
import { Box } from '@material-ui/core'
import { useObjectValidation, XForm } from '@speedlo/xform'

import { FormInputField } from '../../../../molecules/forms/FormInputField'
import { FormSubmitButton } from '../../../../molecules/forms/FormSubmitButton'
import { VerticalGap } from '../../../../molecules/VerticalGap'
import { TGoPayCredentialsForm } from './gopay.types'

type TProps = NoChildren & {
  isVerified: boolean
  credentials: GoPay.TSegmentCredentials
  extraButtons?: ReactNode
  onSubmit(credentials: TGoPayCredentialsForm): Promise<any>
}

export const GoPayCredentials: React.FC<TProps> = ({
  isVerified,
  credentials,
  onSubmit,
  extraButtons,
}) => {
  const requiredMsg = t`Musí být vyplněno`
  const wrongGoIdMsg = t`Ujistěte se, že zadaná hodnota je správně.`

  const initialValues: TGoPayCredentialsForm = {
    clientId: credentials.clientId,
    clientSecret: credentials.clientSecret,
    goId: credentials.goId,
  }

  const validationSchema = useObjectValidation(yup => ({
    clientId: yup.string().required(requiredMsg),
    clientSecret: yup.string().required(requiredMsg),
    goId: yup
      .string()
      .matches(/^[0-9]{10}$/, wrongGoIdMsg)
      .required(requiredMsg),
  }))

  return (
    <XForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <FormInputField
        fieldName="clientId"
        label={`Client ID`}
        fullWidth
        required
        disabled={isVerified}
      />
      <FormInputField
        fieldName="clientSecret"
        label={`Client Secret`}
        fullWidth
        required
        disabled={isVerified}
      />
      <FormInputField
        fieldName="goId"
        label={`Go ID`}
        fullWidth
        required
        disabled={isVerified}
      />
      <VerticalGap />
      {isVerified ? null : (
        <Box display="flex" justifyContent="space-between">
          <FormSubmitButton>
            <Trans>Ověřit údaje</Trans>
          </FormSubmitButton>
          {extraButtons}
        </Box>
      )}
    </XForm>
  )
}
