import { LinearProgress, Typography } from '@material-ui/core'
import React from 'react'

import { VerticalGap } from './VerticalGap'

type TProps = Required<SomeChildren>

export const ProgressMessage: React.FC<TProps> = ({ children }) => {
  return (
    <div>
      <Typography color="textSecondary">{children}</Typography>
      <VerticalGap />
      <LinearProgress />
    </div>
  )
}
