import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import React from 'react'
import { segmentLabels } from 'shopify-payment-core'
import { TAccount } from 'shopify-payments-types'

type TProps = NoChildren & {
  account: TAccount
}

export const AccountViewActivationIntro: React.FC<TProps> = ({ account }) => {
  return (
    <Typography paragraph variant="body2">
      <Trans>
        Následující průvodce vás povede při propojení{' '}
        {segmentLabels[account.segmentKey]} brány s Vaším obchodem.{' '}
        <b>Prosím, čtěte pozorně instrukce pro bezproblémový postup</b>.
      </Trans>
    </Typography>
  )
}
