import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import React from 'react'

type TProps = NoChildren

export const ComGateInstallationInfo: React.FC<TProps> = props => {
  return (
    <>
      <Typography paragraph>
        <Trans>
          V této chvíli se ujistěte se, že máte <b>Test mode</b> povolený a
          proveďte první testovací objednávku ve Vašem obchodě zaplacenou přes
          ComGate.
        </Trans>
      </Typography>
      <Typography paragraph>
        <Trans>
          Následně je potřeba kontaktovat podporu společnosti ComGate a požádat
          o aktivaci produkčního prostředí. Nezapomeňte také vypnout{' '}
          <b>Test mode</b> v nastavení Shopify pokud chcete ComGate začít
          používat na ostro.
        </Trans>
      </Typography>
    </>
  )
}
