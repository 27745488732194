import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import React from 'react'

import { usePageTitle } from '../hooks/usePageTitle'

export const NotFoundView: React.FC = () => {
  usePageTitle(i18n._(t`Nenalezeno`))

  return (
    <Typography variant="h4">
      <Trans>Nic tady není, je nám líto.</Trans>
    </Typography>
  )
}
