import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { segmentLabels } from 'shopify-payment-core'
import { SegmentKey } from 'shopify-payments-types'

import { FormCheckboxField } from '../../../molecules/forms/FormCheckboxField'
import { FormInputField } from '../../../molecules/forms/FormInputField'
import { FormSubmitButton } from '../../../molecules/forms/FormSubmitButton'
import { TermsFormCheckbox } from '../../../molecules/forms/TermsFormCheckbox'
import { ShopNameFormInput } from '../../../molecules/ShopNameFormInput'
import { VerticalGap } from '../../../molecules/VerticalGap'

type TProps = NoChildren & {
  segmentKey: SegmentKey
  hidden: boolean
}

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none',
  },
}))

export const RegisterViewFormLayout: React.FC<TProps> = ({
  segmentKey,
  hidden,
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(hidden && classes.hidden)}>
      <Typography paragraph>
        <Trans>
          Abychom mohli začít s implementací našeho {segmentLabels[segmentKey]}{' '}
          řešení pro váš obchod, potřebujeme od vás několik základních
          informací. Poté vám vytvoříme přístup do našeho rozhraní, ve kterém
          budete moci nastavit podrobnější informace o integraci a nainstalovat
          náš modul do vašeho Shopify obchodu.
        </Trans>
      </Typography>
      <ShopNameFormInput />
      <FormInputField
        fieldName="email"
        type="email"
        label={i18n._(t`Kontaktní e-mail`)}
        required
        fullWidth
      />
      <FormInputField
        fieldName="phone"
        label={i18n._(
          t`Kontaktní telefon (nepovinný, pro rychlejší komunikaci)`,
        )}
        fullWidth
      />
      <VerticalGap />
      <TermsFormCheckbox />
      <VerticalGap />
      <FormCheckboxField
        fieldName="newsletter"
        label={
          <Trans>Souhlasím se zasíláním novinek o Shopify a službách</Trans>
        }
      />
      <VerticalGap />
      <FormSubmitButton>
        <Trans>Pokračovat</Trans>
      </FormSubmitButton>
    </div>
  )
}
