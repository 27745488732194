import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Button, Typography } from '@material-ui/core'
import script from 'dynamic-script'
import React from 'react'
import { segmentLabels } from 'shopify-payment-core'
import { TAccount } from 'shopify-payments-types'

import { useAuth } from '../../../app/AuthProvider'
import { FlexBox } from '../../../molecules/FlexBox'

type TProps = NoChildren & {
  account: TAccount
}

type TPlan = 'standard' | 'plus'

const paddleScript = script('https://cdn.paddle.com/paddle/paddle.js')
const getPaddle = () => (window as any).Paddle

const paddleProductByPlan: Record<TPlan, number> = {
  standard: 584887,
  plus: 585462,
}

export const AccountViewInstallationPay: React.FC<TProps> = ({ account }) => {
  const authModel = useAuth()

  React.useEffect(() => {
    paddleScript.then(() => {
      getPaddle().Setup({
        vendor: 109094,
      })
    })
  }, [account, authModel])

  const [plan /*setPlan*/] = React.useState<TPlan>('standard')
  const [completed, setCompleted] = React.useState(false)

  const openCheckout = () => {
    getPaddle().Checkout.open({
      product: paddleProductByPlan[plan],
      title: i18n._(
        t`${segmentLabels[account.segmentKey]} Shopify integrace${
          plan === 'plus' ? ' PLUS' : ''
        }`,
      ),
      message: i18n._(t`Jednorázový poplatek za instalaci a provoz.`),
      email: authModel.user?.email,
      passthrough: account.id,
      allowQuantity: false,
      disableLogout: true,
      successCallback: data => {
        setCompleted(data.isCompleted)
      },
    })
  }

  return (
    <>
      <Typography paragraph variant="h6">
        <Trans>
          Než budete moci platební bránu začít využívat, je nutné{' '}
          <b>zaplatit jednorázový instalační poplatek</b>.<br />
        </Trans>
      </Typography>
      <Typography paragraph variant="subtitle2">
        <Trans>
          Ujistěte se, že do formuláře vyplníte také údaje o Vaší společnosti
          aby došlo ke korektnímu vystavení faktury.
        </Trans>
      </Typography>
      {!completed && (
        <FlexBox>
          <Button onClick={openCheckout} color="primary" variant="contained">
            <Trans>Provést platbu</Trans>
          </Button>
          {/* <Box mr={1} />
          <FormControlLabel
            label={<Trans>Mám aktivní Shopify Plus</Trans>}
            control={
              <Switch
                checked={plan === 'plus'}
                onChange={ev => {
                  setPlan(ev.target.checked ? 'plus' : 'standard')
                }}
              />
            }
          /> */}
        </FlexBox>
      )}
      {completed && (
        <Typography variant="h5">
          Jakmile dostaneme potvrzení o provedené platbě, aktivujeme Váš účet.
        </Typography>
      )}
    </>
  )
}
