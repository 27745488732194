export const appConfig = {
  uri: process.env.REACT_APP_URI,
  persistenceKey: process.env.REACT_APP_PERSISTENCE_KEY,
  pageTitleSuffix: process.env.REACT_APP_PAGE_TITLE_SUFFIX,
  fallbackLanguage: 'cs',
  supportedLanguages: ['cs'],
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL || '',
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
  intercomAppID: process.env.REACT_APP_INTERCOM_APP_ID || '',
  firebase: {
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
}
