import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import React from 'react'

import { ExternalLink } from '../../../../molecules/ExternalLink'
import { FormInputField } from '../../../../molecules/forms/FormInputField'

type TProps = NoChildren

export const ConfirmoConfigCallbackPassword: React.FC<TProps> = props => {
  return (
    <>
      <Typography>
        <Trans>Callback password</Trans>
      </Typography>
      <Typography variant="caption" color="textSecondary">
        <Trans>
          Pro zvýšení bezpečnosti komunikace doporučujeme v Confirmo{' '}
          <ExternalLink href="https://confirmo.net/platform/settings/security">
            vytvořit Callback password
          </ExternalLink>{' '}
          a zadat jej do následujícího pole
        </Trans>
      </Typography>
      <FormInputField fieldName="callbackPassword" fullWidth type="password" />
    </>
  )
}
