import {
  LinearProgress,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { TTheme } from '../../app/ThemeProvider'
import { Footer } from '../../molecules/Footer'
import { MainContent } from './components/MainContent'
import { MainSidebar } from './components/MainSidebar'
import { MainTopBar } from './components/MainTopBar'

type TProps = Required<SomeChildren> & {
  withSidebar?: boolean
}

const useStyles = makeStyles((theme: TTheme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
  },
}))

export const MainLayout: React.FC<TProps> = ({
  children,
  withSidebar = true,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = React.useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const shouldOpenSidebar = withSidebar && (isDesktop ? true : openSidebar)

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <MainTopBar onSidebarOpen={handleSidebarOpen} />
      <MainSidebar
        onClose={handleSidebarClose}
        isOpen={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        <React.Suspense fallback={<LinearProgress />}>
          <MainContent>{children}</MainContent>
          <Footer />
        </React.Suspense>
      </main>
    </div>
  )
}
