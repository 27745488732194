import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Button, Collapse, FormHelperText } from '@material-ui/core'
import React from 'react'

import { ExternalLink } from '../../../../molecules/ExternalLink'
import { FormInputField } from '../../../../molecules/forms/FormInputField'

type TProps = NoChildren

export const ComGateConfigBankAccount: React.FC<TProps> = props => {
  const [moreInfo, showMoreInfo] = React.useState(false)
  return (
    <>
      <FormInputField
        fieldName="bankAccount"
        label={i18n._(
          t`Identifikátor bankovního spojení, na který ComGate převede peníze`,
        )}
        placeholder={i18n._(t`Použít výchozí dle nastavení ComGate účtu`)}
        fullWidth
      />
      <FormHelperText>
        <Trans>
          Seznam identifikátorů najdete na{' '}
          <ExternalLink href="https://portal.comgate.cz/" noIcon>
            https://portal.comgate.cz
          </ExternalLink>
          .{' '}
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            onClick={() => showMoreInfo(b => !b)}
            style={{ fontSize: '0.6rem' }}
          >
            Více informací
          </Button>
        </Trans>
      </FormHelperText>
      <Collapse in={moreInfo}>
        <Trans>
          Standardně je v systémech ComGate Payments přednastaven jen jeden účet
          pro vybíjení CZK a EUR. Přejete-li si, aby se bankovní spojení pro
          vaše obchody odlišovala, tedy aby byly platby zasílány na různé CZK
          nebo EUR účty, uveďte zde identifikátor bankovního spojení, který
          najdete v ComGate rozhraní.{' '}
          <b>Při uvedení neplatného identifikátoru budou platby odmítány!</b>
        </Trans>
      </Collapse>
    </>
  )
}
