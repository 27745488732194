import React from 'react'
import { Route } from 'react-router-dom'

import { useAuth } from '../app/AuthProvider'
import { MainLayout } from '../layouts/MainLayout'
import { TRouteProps } from '../typings/types'
import { RedirectToLogin } from './RedirectToLogin'

type TProps = NoChildren &
  TRouteProps & {
    adminOnly?: boolean
  }

export const SecureRoute: React.FC<TProps> = ({
  view: View,
  adminOnly = false,
  ...routeProps
}) => {
  const auth = useAuth()
  if (auth.isAuthenticated && (!adminOnly || auth.user?.isAdmin)) {
    return (
      <Route
        {...routeProps}
        render={() => (
          <MainLayout>
            <View />
          </MainLayout>
        )}
      />
    )
  }
  return <RedirectToLogin useOrigin />
}
