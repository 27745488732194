import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React from 'react'

import { FormInputField } from '../../../../../molecules/forms/FormInputField'

type TProps = NoChildren

export const AccountDashboardPaymentLabel: React.FC<TProps> = () => {
  return (
    <FormInputField
      fullWidth
      fieldName="paymentLabel"
      label={i18n._(t`Popis platby pro zákazníka`)}
      placeholder={i18n._(
        t`Platba objednávky z obchodu [Název Shopify obchodu]`,
      )}
      trimOnChange={false}
    />
  )
}
