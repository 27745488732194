import { Trans } from '@lingui/macro'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { CheckCircleOutline, HelpOutline, HighlightOff, Send, SvgIconComponent } from '@material-ui/icons'
import clsx from 'clsx'
import React from 'react'

import { TActivationStatus } from '../accountview.types'

type TProps = NoChildren & {
  status: TActivationStatus
  handleContinue(): void
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    minWidth: '13rem',
    '& h6': {
      textAlign: 'center',
    },
  },
  icon: {
    '& svg': {
      fontSize: '4rem',
    },
  },
  verified: {
    color: green[600],
  },
  error: {
    color: theme.palette.error.main,
  },
  unknown: {
    color: theme.palette.grey[600],
  },
  continueButton: {
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))

const statusIcon: Record<TProps['status'], SvgIconComponent> = {
  unknown: HelpOutline,
  verified: CheckCircleOutline,
  error: HighlightOff,
}

const getStatusMessage = (status: TProps['status']): ReactNode => {
  switch (status) {
    case 'unknown':
      return <Trans>Neověřeno</Trans>
    case 'error':
      return <Trans>Chyba</Trans>
    case 'verified':
      return <Trans>Ověřeno</Trans>
  }
}

export const AccountViewActivationStatus: React.FC<TProps> = ({
  status,
  handleContinue,
}) => {
  const classes = useStyles()

  const Icon = statusIcon[status]
  const messageNode = getStatusMessage(status)
  const buttonNode = status === 'verified' && (
    <Box mt={1}>
      <Button
        className={classes.continueButton}
        color="primary"
        variant="contained"
        onClick={handleContinue}
        endIcon={<Send />}
      >
        Pokračovat
      </Button>
    </Box>
  )

  return (
    <div className={clsx(classes.root, classes[status])}>
      <div className={classes.icon}>
        <Icon />
      </div>
      <Typography color="inherit" variant="h6">
        {messageNode}
      </Typography>
      {buttonNode}
    </div>
  )
}
