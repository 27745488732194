import React from 'react'

import { Confirmo } from 'shopify-payments-types'

import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Box, Button } from '@material-ui/core'
import { useObjectValidation, XForm } from '@speedlo/xform'

import { FormInputField } from '../../../../molecules/forms/FormInputField'
import { FormSubmitButton } from '../../../../molecules/forms/FormSubmitButton'
import { VerticalGap } from '../../../../molecules/VerticalGap'
import { TConfirmoCredentialsForm } from './confirmo.types'

type TProps = NoChildren & {
  isVerified: boolean
  credentials: Confirmo.TSegmentCredentials
  onSubmit(credentials: TConfirmoCredentialsForm): Promise<any>
  onRegHintClick?(): void
}

export const ConfirmoCredentials: React.FC<TProps> = ({
  isVerified,
  credentials,
  onSubmit,
  onRegHintClick,
}) => {
  const requiredMsg = i18n._(t`Musí být vyplněno`)

  const initialValues: TConfirmoCredentialsForm = {
    apiKey: credentials.apiKey,
  }

  const validationSchema = useObjectValidation(yup => ({
    apiKey: yup.string().required(requiredMsg),
  }))

  return (
    <XForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <FormInputField
        fieldName="apiKey"
        label={i18n._(t`API klíč`)}
        fullWidth
        required
        disabled={isVerified}
      />
      <VerticalGap />
      {isVerified ? null : (
        <Box display="flex" justifyContent="space-between">
          <FormSubmitButton>
            <Trans>Ověřit klíč</Trans>
          </FormSubmitButton>
          {onRegHintClick && (
            <Box ml={1}>
              <Button variant="outlined" onClick={onRegHintClick}>
                <Trans>Confirmo účet ještě nemáte?</Trans>
              </Button>
            </Box>
          )}
        </Box>
      )}
    </XForm>
  )
}
