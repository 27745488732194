import qs from 'qs-lite'
import React from 'react'

export function useQueryParams() {
  const { search } = window.location
  const searchParams = React.useMemo(() => new URLSearchParams(search), [
    search,
  ])

  const getQueryParam = React.useCallback(
    (param: string) => searchParams.get(param),
    [searchParams],
  )

  const getAllQueryParams = React.useCallback(() => {
    return qs.parse(searchParams.toString())
  }, [searchParams])

  const createQueryString = (keyValueObj: Record<string, string>) => {
    return Object.entries(keyValueObj)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
  }

  return { getAllQueryParams, getQueryParam, createQueryString }
}
