import { Box } from '@material-ui/core'
import React from 'react'

type TProps = Required<SomeChildren>

export const MainContent: React.FC<TProps> = ({ children }) => {
  return (
    <Box m={1} p={2}>
      {children}
    </Box>
  )
}
