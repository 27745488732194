import React from 'react'
import { TCallableFunctions } from 'shopify-payments-types'

import { getFirebase } from '../tools/firebase'

export function useFirebaseFunction<
  TFuncName extends TCallableFunctions['name']
>(name: TFuncName) {
  const { functions } = getFirebase()
  const func = React.useMemo(() => functions.httpsCallable(name), [
    functions,
    name,
  ])

  type TFunc = Extract<TCallableFunctions, { name: TFuncName }>
  return React.useCallback(
    async (data: TFunc['data']): Promise<TFunc['result']> => {
      const ret = await func(data)
      return ret.data
    },
    [func],
  )
}
