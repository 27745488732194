import { Grid } from '@material-ui/core'
import React from 'react'

type TProps = Required<SomeChildren>

export const GridItemSmall: React.FC<TProps> = ({ children }) => {
  return (
    <Grid item lg={3} sm={6} xl={3} xs={12}>
      {children}
    </Grid>
  )
}
