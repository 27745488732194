import { Sentry } from '@speedlo/sentry'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { isValidSegment } from 'shopify-payment-core'
import { SegmentKey } from 'shopify-payments-types'
import { useImmer } from 'use-immer'

import { useAuth } from '../../app/AuthProvider'
import { ROUTE_ACCOUNT } from '../../app/routes'
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction'
import { RedirectToRoot } from '../../molecules/RedirectToRoot'
import { TAddAccountFormValues, TAddAccountPhase } from './addaccountview.types'
import { AddAccountViewForm } from './components/AddAccountViewForm'

type TProps = NoChildren

export const AddAccountView: React.FC<TProps> = () => {
  const authModel = useAuth()
  const history = useHistory()
  const match = useRouteMatch<{ segmentKey: SegmentKey }>()
  const segmentKey = match && match.params.segmentKey

  const [state, updateState] = useImmer({
    phase: 'empty' as TAddAccountPhase,
    accountId: null as MaybeID,
  })

  const addAccount = useFirebaseFunction('addAccount')

  const onSubmit = React.useCallback(
    async ({ shopName }: TAddAccountFormValues) => {
      if (!segmentKey || !authModel.user) {
        return
      }
      try {
        const { id, status } = await addAccount({
          shopName,
          segmentKey,
        })
        updateState(draft => {
          if (status === 'duplicate-account') {
            draft.phase = 'duplicate-account'
          } else if (status === 'invalid-shop') {
            draft.phase = 'invalid-shop'
          } else if (status === 'created') {
            history.push(`${ROUTE_ACCOUNT}/${id}`)
          }
        })
      } catch (err) {
        Sentry.withScope(scope => {
          scope.setExtras({ shopName, segmentKey })
          Sentry.captureException(err)
        })
        console.error(err)
        updateState(draft => {
          draft.phase = 'unknown-error'
        })
      }
    },
    [addAccount, authModel, history, segmentKey, updateState],
  )

  if (!(segmentKey && isValidSegment(segmentKey))) {
    return <RedirectToRoot />
  }

  return (
    <AddAccountViewForm
      onSubmit={onSubmit}
      segmentKey={segmentKey}
      phase={state.phase}
    />
  )
}
