import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { SvgIconComponent } from '@material-ui/icons'
import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'

import { useAuth } from '../../../app/AuthProvider'

type TProps = Required<WithChildren<string>> & {
  to: string
  icon: SvgIconComponent
  adminOnly?: boolean
}

export const SidebarViewItem: React.FC<TProps> = ({
  to,
  icon,
  adminOnly = false,
  children,
}) => {
  const authModel = useAuth()

  if (adminOnly && !authModel.isAdmin) {
    return null
  }

  return (
    <ListItem disableGutters button component={RouterLink} to={to}>
      <ListItemIcon>{React.createElement(icon)}</ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </ListItem>
  )
}
