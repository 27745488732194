import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import clsx from 'clsx'
import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { segmentLabels } from 'shopify-payment-core'
import { SegmentKey, TAccount } from 'shopify-payments-types'

import { ROUTE_ACCOUNT } from '../../../app/routes'
import { AccountStatusLabel } from '../../../molecules/AccountStatusLabel'

type TProps = NoChildren & {
  segmentKey: SegmentKey
  accounts: RoA<TAccount>
  activeAccount: MaybeID
  onAdd(): void
}

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    letterSpacing: 0,
    width: '100%',
    '& .MuiTypography-caption': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& .MuiChip-label': {
      textTransform: 'lowercase',
    },
  },
  active: {
    color: theme.palette.text.primary,
    '& .MuiTypography-caption': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}))

export const SidebarViewSegment: React.FC<TProps> = ({
  segmentKey,
  accounts,
  activeAccount,
  onAdd,
}) => {
  const classes = useStyles()
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant="overline" className={classes.heading}>
          {segmentLabels[segmentKey]}
        </Typography>
        <Tooltip title={i18n._(t`Přidat obchod`)}>
          <IconButton color="secondary" size="small" onClick={onAdd}>
            <Add />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      {accounts.length === 0 ? (
        <Box p={1} textAlign="center">
          <Typography variant="caption" color="textSecondary">
            <Trans>Žádné obchody</Trans>
          </Typography>
        </Box>
      ) : (
        <List>
          {accounts.map(({ id, shopName, activationStatus }) => (
            <ListItem key={id} className={classes.item} disableGutters>
              <Button
                component={RouterLink}
                to={`${ROUTE_ACCOUNT}/${id}`}
                className={clsx(
                  classes.button,
                  id === activeAccount && classes.active,
                )}
              >
                <Typography variant="caption">{shopName}</Typography>
                <Chip
                  label={<AccountStatusLabel status={activationStatus} />}
                  size="small"
                  variant="outlined"
                />
              </Button>
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
}
