import React from 'react'

import { GoPay } from 'shopify-payments-types'

import { useAccountActivation } from '../../hooks/useAccountActivation'
import { TGoPayAccount } from './gopay.types'
import { GoPayActivationLayout } from './GoPayActivationLayout'

type TProps = NoChildren & {
  account: TGoPayAccount
}

export const GoPayActivationView: React.FC<TProps> = ({ account }) => {
  const {
    state,
    handleVerifyConfirm,
    handleVerifyCredentials,
  } = useAccountActivation<GoPay.TSegmentCredentials>(account)

  return (
    <GoPayActivationLayout
      activationResult={state}
      handleVerifyCredentials={handleVerifyCredentials}
      handleVerifyConfirm={handleVerifyConfirm}
      account={account}
    />
  )
}
