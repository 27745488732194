import { useSnackbar as useSnackbarOrig, VariantType } from 'notistack'
import React from 'react'

const successVariant = { variant: 'success' as VariantType }
const errorVariant = { variant: 'error' as VariantType }
const warningVariant = { variant: 'warning' as VariantType }
const infoVariant = { variant: 'info' as VariantType }

export function useSnacks() {
  const { enqueueSnackbar } = useSnackbarOrig()
  return {
    enqueueSnackbar,
    enqueueSuccess: React.useCallback(
      (errorMessage: string) => enqueueSnackbar(errorMessage, successVariant),
      [enqueueSnackbar],
    ),
    enqueueError: React.useCallback(
      (errorMessage: string) => enqueueSnackbar(errorMessage, errorVariant),
      [enqueueSnackbar],
    ),
    enqueueWarning: React.useCallback(
      (errorMessage: string) => enqueueSnackbar(errorMessage, warningVariant),
      [enqueueSnackbar],
    ),
    enqueueInfo: React.useCallback(
      (errorMessage: string) => enqueueSnackbar(errorMessage, infoVariant),
      [enqueueSnackbar],
    ),
  }
}
