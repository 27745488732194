import React from 'react'

import { ComGate } from 'shopify-payments-types'

import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Box } from '@material-ui/core'
import { useObjectValidation, XForm } from '@speedlo/xform'

import { FormInputField } from '../../../../molecules/forms/FormInputField'
import { FormSubmitButton } from '../../../../molecules/forms/FormSubmitButton'
import { VerticalGap } from '../../../../molecules/VerticalGap'
import { TComGateCredentialsForm } from './comgate.types'

type TProps = NoChildren & {
  isVerified: boolean
  credentials: ComGate.TSegmentCredentials
  extraButtons?: ReactNode
  onSubmit(credentials: TComGateCredentialsForm): Promise<any>
}

export const ComGateCredentials: React.FC<TProps> = ({
  isVerified,
  credentials,
  onSubmit,
  extraButtons,
}) => {
  const requiredMsg = i18n._(t`Musí být vyplněno`)

  const initialValues: TComGateCredentialsForm = {
    merchant: credentials.merchant,
    secret: credentials.secret,
  }

  const validationSchema = useObjectValidation(yup => ({
    merchant: yup.string().required(requiredMsg),
    secret: yup.string().required(requiredMsg),
  }))

  return (
    <XForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <FormInputField
        fieldName="merchant"
        label={i18n._(t`Identifikátor propojení obchodu`)}
        fullWidth
        required
        disabled={isVerified}
      />
      <FormInputField
        fieldName="secret"
        label={i18n._(t`Heslo`)}
        fullWidth
        required
        disabled={isVerified}
      />
      <VerticalGap />
      {isVerified ? null : (
        <Box display="flex" justifyContent="space-between">
          <FormSubmitButton>
            <Trans>Ověřit údaje</Trans>
          </FormSubmitButton>
          {extraButtons}
        </Box>
      )}
    </XForm>
  )
}
