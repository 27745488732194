import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { Card, CardContent } from '@material-ui/core'
import { useObjectValidation, XForm } from '@speedlo/xform'
import React from 'react'
import { segmentLabels } from 'shopify-payment-core'
import { SegmentKey } from 'shopify-payments-types'

import { usePageTitle } from '../../../hooks/usePageTitle'
import { VerticalGap } from '../../../molecules/VerticalGap'
import {
  TAddAccountFormValues,
  TAddAccountPhase,
} from '../addaccountview.types'
import { AddAccountViewFormLayout } from './AddAccountViewFormLayout'
import { AddAccountViewPhase } from './AddAccountViewPhase'

type TProps = NoChildren & {
  segmentKey: SegmentKey
  phase: TAddAccountPhase
  onSubmit(values: TAddAccountFormValues): Promise<void>
}

export const AddAccountViewForm: React.FC<TProps> = ({
  segmentKey,
  phase,
  onSubmit,
}) => {
  const segmentLabel = segmentLabels[segmentKey]
  usePageTitle(i18n._(t`Registrace obchodu pro ${segmentLabel} bránu`))

  const initialValues = {
    shopName: '',
    terms: false,
  }

  const validationSchema = useObjectValidation(yup => ({
    shopName: yup.string().required(),
    terms: yup
      .boolean()
      .required()
      .oneOf([true]),
  }))

  return (
    <Card>
      <CardContent>
        <XForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <AddAccountViewFormLayout segmentKey={segmentKey} />
        </XForm>
        <VerticalGap />
        <AddAccountViewPhase phase={phase} />
      </CardContent>
    </Card>
  )
}
