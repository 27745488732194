export type TIntercom = {
  (action: 'boot' | 'update', data?: Partial<TData>): void
  (action: 'shutdown'): void
  (action: 'showNewMessage'): void
}

type TData = {
  app_id: string
  email: string
  name: string
  user_id: string
  created_at: number
}

export function loadIntercom(appID: string) {
  return new Promise<TIntercom>((resolve, reject) => {
    ;(function(w, d) {
      function i() {
        i.c(arguments)
      }
      i.q = []
      i.c = function(args) {
        i.q.push(args)
      }
      w.Intercom = i

      const l = () => {
        const s = d.createElement('script')
        s.async = true
        s.src = `https://widget.intercom.io/widget/${appID}`
        s.type = 'text/javascript'
        s.onload = () => resolve(w.Intercom as TIntercom)
        s.onerror = reject
        const x = d.getElementsByTagName('script')[0]
        x.parentNode?.insertBefore(s, x)
      }

      if (d.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    })(window as any, document)
  })
}
