import React from 'react'

import { when } from 'mobx'
import { useHistory } from 'react-router'

import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { Sentry } from '@speedlo/sentry'

import { useAuth } from '../../app/AuthProvider'
import { ROUTE_LOGIN, ROUTE_ROOT } from '../../app/routes'
import { useAuthEmail } from '../../hooks/useAuthEmail'
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useSnacks } from '../../hooks/useSnacks'
import { getFirebase } from '../../tools/firebase'
import { AuthViewCheck } from './components/AuthViewCheck'

type TProps = NoChildren

export const AuthView: React.FC<TProps> = () => {
  const { getQueryParam } = useQueryParams()
  const origin = getQueryParam('o') || ROUTE_ROOT

  const history = useHistory()
  const authModel = useAuth()
  const { enqueueError } = useSnacks()
  const { auth } = getFirebase()

  const signIn = useFirebaseFunction('signIn')

  const handleAuth = React.useCallback(
    async (email: string) => {
      if (!auth.isSignInWithEmailLink(window.location.href)) {
        history.replace(origin)
        return
      }
      try {
        await auth.signInWithEmailLink(email, window.location.href)
      } catch (err) {
        if (err.code === 'auth/expired-action-code') {
          enqueueError(
            i18n._(
              t`Platnost odkazu vypršela. Odeslali jsme Vám zprávu s novým odkazem.`,
            ),
          )
          await signIn({ email, origin })
          return
        }
        enqueueError(`${err.message} (${err.code})`)
        Sentry.captureException(err)
        history.replace(ROUTE_LOGIN)
      }
    },
    [auth, enqueueError, history, origin, signIn],
  )

  const [authEmail, setAuthEmail] = useAuthEmail()

  React.useEffect(() => {
    return when(
      () => authModel.isAuthenticated,
      () => {
        history.replace(origin)
      },
    )
  }, [authModel, history, origin])

  React.useEffect(() => {
    if (authEmail) {
      handleAuth(authEmail).finally(() => {
        setAuthEmail(null)
      })
    }
  }, [authEmail, handleAuth, setAuthEmail])

  return (
    <AuthViewCheck
      origin={origin}
      needToVerify={authEmail === null}
      onSubmit={handleAuth}
    />
  )
}
