import { Trans } from '@lingui/macro'
import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { parseShopId, segmentLabels } from 'shopify-payment-core'

import { usePageTitle } from '../hooks/usePageTitle'

type TProps = NoChildren

export const PageTitle: React.FC<TProps> = () => {
  const { title, accountId } = usePageTitle()
  const shopId = !!accountId && parseShopId(accountId)
  return (
    <>
      <Typography variant="h6">{title}</Typography>
      {shopId && (
        <>
          <PageTitleInfo
            label={<Trans>Brána</Trans>}
            value={segmentLabels[shopId.segmentKey]}
          />
          <PageTitleInfo
            label={<Trans>Obchod</Trans>}
            value={shopId.shopName}
          />
        </>
      )}
    </>
  )
}

function PageTitleInfo({ label, value }: { label: ReactNode; value: string }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      ml={4}
    >
      <Typography variant="caption">{label}</Typography>
      <Typography variant="subtitle2">{value}</Typography>
    </Box>
  )
}
