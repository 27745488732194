import { Trans } from '@lingui/macro'
import { Collapse, Typography } from '@material-ui/core'
import React from 'react'

import { ExternalLink } from '../../../../molecules/ExternalLink'

type TProps = NoChildren & {
  show: boolean
}

export const ConfirmoActivationRegister: React.FC<TProps> = ({ show }) => {
  return (
    <Collapse in={show}>
      <>
        <Trans>
          <Typography variant="subtitle1" gutterBottom>
            Nejprve je potřeba{' '}
            <ExternalLink href="https://confirmo.net/landing/signup">
              zaregistrovat Confirmo účet
            </ExternalLink>{' '}
            a provést úvodní požadované nastavení.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Následně po přihlášení v sekci{' '}
            <ExternalLink href="https://confirmo.net/platform/settings/api-keys">
              API keys
            </ExternalLink>{' '}
            zadejte libovolné jméno (např. Shopify). Vytvořený klíč zkopírujte
            přesně do vstupního pole výše.
          </Typography>
        </Trans>
      </>
    </Collapse>
  )
}
