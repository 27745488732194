import { makeStyles } from '@material-ui/core'
import React from 'react'

import { Footer } from '../../molecules/Footer'
import { GuestContent } from './components/GuestContent'
import { GuestTopBar } from './GuestTopBar'

type TProps = Required<SomeChildren>

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: '100%',
  },
  content: {
    height: '100%',
  },
  paper: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    top: '30%',
    padding: '1rem',
    width: '40rem',
  },
}))

export const GuestLayout: React.FC<TProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <GuestTopBar />
      <main className={classes.content}>
        <GuestContent>{children}</GuestContent>
        <Footer />
      </main>
    </div>
  )
}
