/* eslint-disable no-script-url */
import { Trans } from '@lingui/macro'
import { Link, Typography } from '@material-ui/core'
import { LinkProps } from '@material-ui/core/Link'
import { CheckCircleOutline } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { ROUTE_LOGIN } from '../../../app/routes'
import { ContactLink } from '../../../molecules/ContactButton'
import { ErrorMessage } from '../../../molecules/ErrorMessage'
import { ProgressMessage } from '../../../molecules/ProgressMessage'
import { VerticalGap } from '../../../molecules/VerticalGap'
import { TRegistrationPhase } from '../registerview.types'

type TProps = NoChildren & {
  phase: TRegistrationPhase
  handleRetry?(): void
}

const useStyles = makeStyles(theme => ({
  success: {
    '& .MuiTypography-h4': {
      paddingLeft: '5rem',
    },
    '& .MuiSvgIcon-root': {
      float: 'left',
      fontSize: '4rem',
      marginBottom: '1rem',
    },
    '& button': {
      marginTop: '-1px',
    },
  },
}))

export const RegisterViewPhase: React.FC<TProps> = ({ phase, handleRetry }) => {
  const classes = useStyles()

  if (phase === 'submitting') {
    return (
      <ProgressMessage>
        <Trans>Probíhá zpracování registrace.</Trans>
      </ProgressMessage>
    )
  }

  if (phase === 'submitted') {
    return (
      <ProgressMessage>
        <Trans>
          Registrace úspěšně zpracována. Probíhá odesílání emailové zprávy.
        </Trans>
      </ProgressMessage>
    )
  }

  if (phase === 'pending') {
    return (
      <div className={classes.success}>
        <div>
          <CheckCircleOutline color="action" />
        </div>
        <VerticalGap />
        <Typography variant="h4" color="textPrimary" paragraph>
          <Trans>Děkujeme za registraci.</Trans>
        </Typography>
        <VerticalGap />
        <Typography color="textPrimary" paragraph>
          <Trans>
            Na zadanou emailovou adresu jsme vám odeslali zprávu s dalšími
            pokyny pro pokračování registrace.
          </Trans>
        </Typography>
        <Typography color="textPrimary" paragraph>
          <Trans>Toto okno prohlížeče můžete nyní zavřít.</Trans>
        </Typography>
        <Typography variant="caption">
          <Trans>
            Pokud zprávu neobdržíte do přibližně 10 minut, zkuste{' '}
            <Link
              component="button"
              variant="caption"
              color="textSecondary"
              onClick={handleRetry}
            >
              odeslat formulář znovu
            </Link>{' '}
            nebo <ContactLink />.
          </Trans>
        </Typography>
      </div>
    )
  }

  if (phase === 'duplicate-account') {
    return (
      <ErrorMessage>
        <Trans>
          Požadovaný obchod je již registrovaný s jinou emailovou adresou. Pokud
          se domnívate, že se jedná o chybu <ContactLink />
        </Trans>
      </ErrorMessage>
    )
  }

  if (phase === 'login-to-access') {
    return (
      <ErrorMessage>
        <Trans>
          Pro zadaný obchod již máte platnou registraci,{' '}
          <LoginLink color="textPrimary" />.
        </Trans>
      </ErrorMessage>
    )
  }

  if (phase === 'login-to-register') {
    return (
      <div className={classes.success}>
        <Typography color="textPrimary">
          <Trans>
            Pro zadanou emailovou adresu již máte vytvořený účet,{' '}
            <LoginLink color="textSecondary" /> a přidejte další obchod přímo z
            aplikace.
            <br />
            <br />
            Pokud se domnívate, že se jedná o chybu <ContactLink />.
          </Trans>
        </Typography>
      </div>
    )
  }

  if (phase === 'email-failed') {
    return (
      <ErrorMessage>
        <Trans>
          Odesílání emailové zprávy se nezdařilo. Prosím zkontrolujte zadanou
          adresou nebo zkuste akci opakovat. Pokud problém přetrvává,{' '}
          <ContactLink />.
        </Trans>
      </ErrorMessage>
    )
  }

  if (phase === 'unknown-error') {
    return (
      <ErrorMessage>
        <Trans>
          Nastala neočekávána chyba při registraci. Pokud se problém bude
          opakovat, prosím <ContactLink />.
        </Trans>
      </ErrorMessage>
    )
  }

  if (phase === 'invalid-shop') {
    return (
      <ErrorMessage>
        <Trans>
          Zadaná URL obchodu se zdá být neplatná. Je nutné použít přesný název
          tak jak jej vidite v adresním řádku před <i>myshopify.com</i>. Pokud
          jste si jisti zadáním a registraci přesto nelze odeslat,{' '}
          <ContactLink />.
        </Trans>
      </ErrorMessage>
    )
  }

  return null
}

function LoginLink({ color }: { color: LinkProps['color'] }) {
  return (
    <Link color={color} to={ROUTE_LOGIN} component={RouterLink}>
      <Trans>přihlašte se, prosím</Trans>
    </Link>
  )
}
