import { FormHelperText, TextField } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import {
  useXField,
  useXFormState,
  WithXField,
  XFieldValidator,
} from '@speedlo/xform'
import { useObserver } from 'mobx-react-lite'
import React from 'react'

type TProps = NoChildren &
  WithXField &
  TextFieldProps & {
    validate?: XFieldValidator<string>
    trimOnChange?: boolean
  }

export const FormInputField = React.forwardRef<HTMLInputElement, TProps>(
  (
    { fieldName, validate, trimOnChange = true, ...textFieldProps },
    inputRef,
  ) => {
    const field = useXField<string>(fieldName, { validate })
    const state = useXFormState()
    return useObserver(() => (
      <>
        <TextField
          error={field.touched && field.hasError}
          value={field.value || ''}
          disabled={state.isSubmitting}
          onChange={ev => {
            if (trimOnChange) {
              field.setValue(ev.target.value?.trim())
            } else {
              field.setValue(ev.target.value)
            }
          }}
          onBlur={() => field.setTouched()}
          margin="dense"
          id={field.name}
          name={field.name}
          ref={inputRef}
          {...textFieldProps}
        />
        {field.touched && field.hasError && (
          <FormHelperText error>{field.error}</FormHelperText>
        )}
      </>
    ))
  },
)
