import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import firebase from 'firebase/app'
import { defaultConfig } from 'shopify-payment-core'

import { appConfig } from '../config'

export function getFirebase(appName?: string) {
  let app: firebase.app.App
  try {
    app = firebase.app(appName)
  } catch {
    app = firebase.initializeApp(
      {
        ...appConfig.firebase,
        authDomain: window.location.hostname,
      },
      appName,
    )
  }
  return {
    auth: app.auth(),
    firestore: app.firestore(),
    functions: app.functions(defaultConfig.functionsRegion),
  }
}
