import React from 'react'

import { SegmentKey, TAccount } from 'shopify-payments-types'

import { Trans } from '@lingui/macro'
import { Box, Typography } from '@material-ui/core'

import { ExternalLink } from '../../../molecules/ExternalLink'
import { VerticalGap } from '../../../molecules/VerticalGap'
import { ComGateInstallationInfo } from './comgate/ComGateInstallationInfo'
import { ConfirmoInstallationInfo } from './confirmo/ConfirmoInstallationInfo'
import { ShopifyCredentialBox } from './dashboard/components/ShopifyCredentialBox'
import { GoPayInstallationInfo } from './gopay/GoPayInstallationInfo'

type TProps = NoChildren & {
  account: TAccount
  settingsLink: string
  segmentLabel: string
}

const infoBySegment: Record<SegmentKey, React.FC> = {
  gopay: GoPayInstallationInfo,
  comgate: ComGateInstallationInfo,
  confirmo: ConfirmoInstallationInfo,
}

export const AccountViewInstallationConfig: React.FC<TProps> = ({
  account,
  settingsLink,
  segmentLabel,
}) => {
  return (
    <>
      <Typography paragraph>
        <Trans>
          Pokračujte do{' '}
          <ExternalLink href={settingsLink}>
            seznamu platebních zprostředkovatelů
          </ExternalLink>{' '}
          a zde vyberte <b>{segmentLabel}</b>.
        </Trans>
      </Typography>
      <Typography paragraph>
        <Trans>
          Budete vyzváni k vyplnění následujících údajů. Potvrďte tlačítkem{' '}
          <b>Activate {segmentLabel}</b> v Shopify formuláři.
        </Trans>
      </Typography>
      <Box maxWidth="40rem">
        <ShopifyCredentialBox label={'Account name'} value={account.id} />
        <ShopifyCredentialBox
          label={'Account secret'}
          value={account.shopifySecret}
        />
      </Box>
      <VerticalGap />
      {React.createElement(infoBySegment[account.segmentKey])}
    </>
  )
}
