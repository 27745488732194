import React from 'react'

import { segmentLabels } from 'shopify-payment-core'
import { SegmentKey } from 'shopify-payments-types'

import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useObjectValidation, XForm } from '@speedlo/xform'

import { usePageTitle } from '../../../hooks/usePageTitle'
import { VerticalGap } from '../../../molecules/VerticalGap'
import {
  TRegistrationFormValues,
  TRegistrationPhase,
} from '../registerview.types'
import { RegisterViewFormLayout } from './RegisterViewFormLayout'
import { RegisterViewPhase } from './RegisterViewPhase'

type TProps = NoChildren & {
  segmentKey: SegmentKey
  phase: TRegistrationPhase
  resetPhase(): void
  onSubmit(values: TRegistrationFormValues): Promise<void>
}

const showFormAtPhases: RoA<TRegistrationPhase> = [
  'empty',
  'email-failed',
  'duplicate-account',
  'unknown-error',
  'login-to-access',
  'invalid-shop',
]

export const RegisterViewForm: React.FC<TProps> = ({
  segmentKey,
  phase,
  resetPhase,
  onSubmit,
}) => {
  const segmentLabel = segmentLabels[segmentKey]
  usePageTitle(i18n._(t`Registrace obchodu pro ${segmentLabel} bránu`))

  const initialValues = {
    email: '',
    shopName: '',
    phone: '',
    terms: false,
    newsletter: true,
  }

  const validationSchema = useObjectValidation(yup => ({
    phone: yup.string(),
    shopName: yup.string().required(),
    email: yup
      .string()
      .required()
      .email(),
    terms: yup
      .boolean()
      .required()
      .oneOf([true]),
    newsletter: yup.boolean().oneOf([true, false]),
  }))

  const formIsHidden = !showFormAtPhases.includes(phase)

  return (
    <div>
      <XForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <RegisterViewFormLayout segmentKey={segmentKey} hidden={formIsHidden} />
      </XForm>
      {!formIsHidden && <VerticalGap />}
      <RegisterViewPhase phase={phase} handleRetry={resetPhase} />
    </div>
  )
}
