import React from 'react'

import { TAccount } from 'shopify-payments-types'

import { Trans } from '@lingui/macro'
import { Box, Card, CardContent, Typography } from '@material-ui/core'

import { CardTitle } from '../../../../molecules/CardTitle'
import { GridItemSmall } from '../../../../molecules/GridItemSmall'
import { ShopifyCredentialBox } from './components/ShopifyCredentialBox'

type TProps = NoChildren & {
  account: TAccount
}

export const AccountDashboardShopifyCredentials: React.FC<TProps> = ({
  account,
}) => {
  return (
    <GridItemSmall>
      <Card>
        <CardTitle>
          <Trans>Údaje pro Shopify nastavení</Trans>
        </CardTitle>
        <CardContent>
          <Typography paragraph>
            <Trans>
              Tyto údaje je nutné mít vyplněné v nastavení platební brány v
              Shopify.
            </Trans>
          </Typography>
          <Box>
            <ShopifyCredentialBox label={'Account name'} value={account.id} />
            <ShopifyCredentialBox
              label={'Account secret'}
              value={account.shopifySecret}
            />
          </Box>
        </CardContent>
      </Card>
    </GridItemSmall>
  )
}
