import { Trans } from '@lingui/macro'
import React from 'react'

import { ContactLink } from '../../../molecules/ContactButton'
import { ErrorMessage } from '../../../molecules/ErrorMessage'
import { ProgressMessage } from '../../../molecules/ProgressMessage'
import { TAddAccountPhase } from '../addaccountview.types'

type TProps = NoChildren & {
  phase: TAddAccountPhase
}

export const AddAccountViewPhase: React.FC<TProps> = ({ phase }) => {
  if (phase === 'submitting') {
    return (
      <ProgressMessage>
        <Trans>Probíhá zpracování registrace.</Trans>
      </ProgressMessage>
    )
  }

  if (phase === 'duplicate-account') {
    return (
      <ErrorMessage>
        <Trans>
          Požadovaný obchod je již registrovaný jiným uživatelem. Pokud se
          domnívate, že se jedná o chybu <ContactLink />
        </Trans>
      </ErrorMessage>
    )
  }

  if (phase === 'unknown-error') {
    return (
      <ErrorMessage>
        <Trans>
          Nastala neočekávána chyba při registraci. Pokud se problém bude
          opakovat, prosím <ContactLink />.
        </Trans>
      </ErrorMessage>
    )
  }

  if (phase === 'invalid-shop') {
    return (
      <ErrorMessage>
        <Trans>
          Zadaná URL obchodu se zdá být neplatná. Je nutné použít přesný název
          tak jak jej vidite v adresním řádku před <i>myshopify.com</i>. Pokud
          jste si jisti zadáním a registraci přesto nelze odeslat,{' '}
          <ContactLink />.
        </Trans>
      </ErrorMessage>
    )
  }

  return null
}
