import { makeStyles, SvgIcon } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

type TProps = NoChildren

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(3),
    fontSize: '2.8rem',
  },
}))

export const TopBarLogo: React.FC<TProps> = props => {
  const classes = useStyles()
  return (
    <SvgIcon
      className={clsx(classes.root, 'MuiAppBar-colorPrimary')}
      viewBox="0 0 115 115"
    >
      <g>
        <path
          d="M57.5,115 C25.7436269,115 0,89.2563731 0,57.5 C0,25.7436269 25.7436269,0 57.5,0 C89.2563731,0 115,25.7436269 115,57.5 C114.961398,89.2403699 89.2403699,114.961398 57.5,115 L57.5,115 Z M57.5,5.25434054 C28.645519,5.25434054 5.25434054,28.645519 5.25434054,57.5 C5.25434054,86.354481 28.645519,109.745659 57.5,109.745659 C86.354481,109.745659 109.745659,86.354481 109.745659,57.5 C109.707046,28.6643646 86.3356468,5.30080002 57.5,5.27185501 L57.5,5.25434054 Z"
          id="Shape"
        ></path>
        <rect x="59" y="28" width="7" height="57" rx="0.45899996"></rect>
        <rect x="27" y="52" width="7" height="10" rx="0.45899996"></rect>
        <rect x="75" y="45" width="7" height="25" rx="0.46439996"></rect>
        <rect x="43" y="38" width="7" height="37" rx="0.45899996"></rect>
      </g>
    </SvgIcon>
  )
}
