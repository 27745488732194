import { Drawer, makeStyles } from '@material-ui/core'
import { DrawerProps } from '@material-ui/core/Drawer'
import { useObserver } from 'mobx-react-lite'
import React from 'react'

import { TTheme } from '../../../app/ThemeProvider'
import SidebarView from '../../../views/SidebarView'

type TProps = NoChildren & {
  variant: DrawerProps['variant']
  isOpen: boolean
  onClose(): void
}

const useStyles = makeStyles((theme: TTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  nav: {
    flex: '1 0 auto',
    marginBottom: theme.spacing(2),
  },
}))

export const MainSidebar: React.FC<TProps> = ({ variant, isOpen, onClose }) => {
  const classes = useStyles()

  return useObserver(() => (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      open={isOpen}
      onClose={onClose}
      variant={variant}
    >
      <div className={classes.root}>
        <SidebarView className={classes.nav} />
      </div>
    </Drawer>
  ))
}
