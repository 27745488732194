import { Trans } from '@lingui/macro'
import { Collapse, Typography } from '@material-ui/core'
import React from 'react'

import { ExternalLink } from '../../../../molecules/ExternalLink'
import { VerticalGap } from '../../../../molecules/VerticalGap'

type TProps = NoChildren & {
  show: boolean
}

export const GoPayActivationRegister: React.FC<TProps> = ({ show }) => {
  return (
    <Collapse in={show}>
      <>
        <VerticalGap />
        <Typography variant="subtitle1" gutterBottom>
          <Trans>
            Je potřeba{' '}
            <ExternalLink href="https://account.gopay.com/dodavatele/ents?lang=cs">
              vyplnit formulář společnosti GoPay
            </ExternalLink>{' '}
            a jakmile budete mít potřebné údaje, vraťte se na tuto stránku a
            vyplňte je.
          </Trans>
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <Trans>
            Pokud jste si učet zřídili již dříve, ale zadané údaje nefungují,
            oznamte společnosti GoPay, že budete využívat integraci od
            společnosti <b>Sounds Good Agency s.r.o.</b> pro urychlení procesu.
          </Trans>
        </Typography>
      </>
    </Collapse>
  )
}
