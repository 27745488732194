import { observable, toJS } from 'mobx'
import { useObserver } from 'mobx-react-lite'
import React from 'react'

const pageTitle = observable({
  title: 'Platební brány.cz',
  accountId: undefined as ID | undefined,
})

export function usePageTitle(newTitle?: string, accountId?: ID) {
  const previousTitle = React.useRef(pageTitle.title)
  const previousAccountId = React.useRef(pageTitle.accountId)

  React.useEffect(() => {
    if (newTitle) {
      pageTitle.title = newTitle
      pageTitle.accountId = accountId
      document.title = newTitle
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      pageTitle.title = previousTitle.current
      // eslint-disable-next-line react-hooks/exhaustive-deps
      pageTitle.accountId = previousAccountId.current
    }
  }, [newTitle, accountId])

  return useObserver(() => {
    return toJS(pageTitle)
  })
}
