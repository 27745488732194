import { Trans } from '@lingui/macro'
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { TAccount } from 'shopify-payments-types'

import comgateSetup from '../../../../assets/comgateSetup.png'
import { GridItemSmall } from '../../../../molecules/GridItemSmall'
import { VerticalGap } from '../../../../molecules/VerticalGap'

type TProps = NoChildren & {
  account: TAccount
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  img: {
    height: 567,
    display: 'block',
  },
  urlAbs: {
    position: 'absolute',
    left: '13.2rem',
  },
  url: {
    position: 'relative',
    top: '-13.9rem',
    color: 'black',
    fontSize: '1rem',
    marginBottom: '0.7rem',
  },
  centerContent: {
    textAlign: 'center',
  },
  dialogContent: {
    overflow: 'hidden',
    margin: 'auto',
  },
}))

export const ComGateConnectionSetup: React.FC<TProps> = ({ account }) => {
  const classes = useStyles()

  const getUrl = (type: 'status' | 'finish') =>
    `https://shopify-gateway.herokuapp.com/comgate/${type}/${
      account.id
      // eslint-disable-next-line no-template-curly-in-string
    }${type === 'finish' ? '/${id}' : ''}`

  const [imgOpen, setImgOpen] = React.useState(false)

  return (
    <GridItemSmall>
      <Card>
        <CardContent>
          <Typography paragraph>
            <Trans>
              Pokud se objevují problemy při dokončování plateb v ComGate
              platební bráně, zkontrolujte prosím nastavení.
            </Trans>
            <VerticalGap />
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setImgOpen(true)}
            >
              <Trans>Zkontrolovat</Trans>
            </Button>
          </Typography>
          <Dialog
            open={imgOpen}
            fullWidth
            maxWidth="md"
            onClose={() => setImgOpen(false)}
          >
            <DialogContent className={classes.dialogContent}>
              <div className={classes.root}>
                <img src={comgateSetup} className={classes.img} alt="" />
                <div className={classes.urlAbs}>
                  <div className={classes.url}>{getUrl('finish')}</div>
                  <div className={classes.url}>{getUrl('finish')}</div>
                  <div className={classes.url}>{getUrl('finish')}</div>
                  <div className={classes.url}>{getUrl('status')}</div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </GridItemSmall>
  )
}
