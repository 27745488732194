import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { useMachine } from '@xstate/react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { TAccount } from 'shopify-payments-types'
import { Machine } from 'xstate'

import { useAuth } from '../../../../app/AuthProvider'
import { ROUTE_LOGOUT, ROUTE_ROOT } from '../../../../app/routes'
import { useFirebaseFunction } from '../../../../hooks/useFirebaseFunction'
import { CardTitle } from '../../../../molecules/CardTitle'
import { GridItemSmall } from '../../../../molecules/GridItemSmall'

type TProps = NoChildren & {
  account: TAccount
}

const useStyles = makeStyles({
  accountName: {
    userSelect: 'none',
    fontSize: '1.2rem',
  },
})

export const AccountDashboardDeleteAccount: React.FC<TProps> = ({
  account,
}) => {
  const authModel = useAuth()
  const classes = useStyles()

  const history = useHistory()
  const deleteAccount = useFirebaseFunction('deleteAccount')

  const [current, send] = useMachine(
    createMachine(account.id, async () => {
      const { deletedUser } = await deleteAccount({ accountId: account.id })
      if (deletedUser === authModel.uid) {
        history.replace(ROUTE_LOGOUT)
      } else {
        history.replace(ROUTE_ROOT)
      }
    }),
  )

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value.trim()
    send('CHANGE', { value })
  }

  return (
    <GridItemSmall>
      <Dialog open={current.matches('open')}>
        <DialogContent>
          <Typography gutterBottom>
            <Trans>
              Opište přesný název účtu{' '}
              <b className={classes.accountName}>{account.id}</b> jako potvrzení
              pro smazání tohoto obchodního účtu. Pokud je toto Váš jediný
              obchodní účet, odstraníme také uživatelský účet a odhlásíme Vás z
              aplikace.
            </Trans>
          </Typography>
          <TextField fullWidth onChange={onChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => send('CANCEL')} color="default">
            <Trans>Zrušit</Trans>
          </Button>
          <Button
            color="secondary"
            variant={current.matches('open.waiting') ? 'outlined' : 'contained'}
            onClick={() => send('PROCEED')}
            disabled={!current.matches('open.confirmed')}
            placeholder={i18n._(t`Opiště celý název účtu`)}
          >
            {current.matches('open.processing') ? (
              <Trans>Probíha odstraňování</Trans>
            ) : (
              <Trans>Odstranit účet</Trans>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardTitle>
          <Trans>Odstranění účtu</Trans>
        </CardTitle>
        <CardContent>
          <Typography gutterBottom>
            <Trans>
              Nechcete již nadále integraci využívat a přejete si smazat veškerá
              data o tomto účtu z naší databáze?
            </Trans>
          </Typography>
          <Button onClick={() => send('OPEN')} color="secondary">
            <Trans>Přejit k nevratnému odstranění</Trans>
          </Button>
        </CardContent>
      </Card>
    </GridItemSmall>
  )
}

type TContext = {}

type TSchema = {
  states: {
    idle: {}
    open: {
      states: {
        waiting: {}
        confirmed: {}
        processing: {}
      }
    }
  }
}

type TEvents =
  | { type: 'OPEN' }
  | { type: 'CANCEL' }
  | { type: 'CHANGE'; value: string }
  | { type: 'PROCEED' }

function createMachine(accountId: ID, onDeleteAccount: () => Promise<any>) {
  return Machine<TContext, TSchema, TEvents>({
    id: 'deleteAccount',
    initial: 'idle',
    states: {
      idle: {
        on: {
          OPEN: 'open',
        },
      },
      open: {
        initial: 'waiting',
        on: {
          CANCEL: '#deleteAccount.idle',
        },
        states: {
          waiting: {
            on: {
              CHANGE: {
                cond: (ctx, ev) => ev.value === accountId,
                target: 'confirmed',
              },
            },
          },
          confirmed: {
            on: {
              PROCEED: 'processing',
              CHANGE: {
                cond: (ctx, ev) => ev.value !== accountId,
                target: 'waiting',
              },
            },
          },
          processing: {
            invoke: {
              src: onDeleteAccount,
              onDone: '#deleteAccount.idle',
            },
          },
        },
      },
    },
  })
}
