import React from 'react'

import { ROUTE_REGISTER_FINISH } from '../../../app/routes'
import { AuthViewContinue } from './AuthViewContinue'
import { AuthViewVerify } from './AuthViewVerify'

type TProps = NoChildren & {
  origin: string
  needToVerify: boolean
  onSubmit(email: string): Promise<void>
}

export const AuthViewCheck: React.FC<TProps> = ({
  origin,
  needToVerify,
  onSubmit,
}) => {
  const isRegistration = origin.indexOf(ROUTE_REGISTER_FINISH) >= 0

  return needToVerify ? (
    <AuthViewVerify isRegistration={isRegistration} onSubmit={onSubmit} />
  ) : (
    <AuthViewContinue isRegistration={isRegistration} />
  )
}
